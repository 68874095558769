import { HttpGetAllMessagesGateway } from "../gateways/HttpGetAllMessagesGateway";
import { HttpCreateMessageGateway } from "../gateways/HttpCreateMessageGateway";
import { HttpDeleteMessageGateway } from "../gateways/HttpDeleteMessageGateway";
import { HttpUpdateMessageGateway } from "../gateways/HttpUpdateMessageGateway";
import { HttpGetMessageGateway } from "../gateways/HttpGetMessageGateway";

import { MessagesHomePresenter } from "./MessagesHomePresenter";
import { MessagesCreatePresenter } from "./MessagesCreatePresenter";
import { MessagesUpdatePresenter } from "./MessagesUpdatePresenter";

export const getMessagesHomePresenter = (viewHandlers) => {
  const getAllMessagesGateway = HttpGetAllMessagesGateway();
  const getDeleteMessageGateway = HttpDeleteMessageGateway();
  return MessagesHomePresenter(
    getAllMessagesGateway,
    getDeleteMessageGateway,
    viewHandlers
  );
};

export const getMessageCreatePresenter = (viewHandlers) => {
  const getCreateMessageGateway = HttpCreateMessageGateway();
  return MessagesCreatePresenter(getCreateMessageGateway, viewHandlers);
};

export const getMessagesUpdatePresenter = (viewHandlers) => {
  const getUpdateMessageGateway = HttpUpdateMessageGateway();
  const getMessageByIDGateway = HttpGetMessageGateway();
  return MessagesUpdatePresenter(
    getUpdateMessageGateway,
    getMessageByIDGateway,
    viewHandlers
  );
};
