import React, { useEffect } from "react";
import "./styles.scss";
import { useLocation } from "react-router-dom";
import { Box, Divider, Typography } from "@mui/material";
import ActivitiesForm from "../../components/ActivitiesForm";
import { getActivitiesUpdatePresenter } from "../../infrastructure/presentation/presenterProvider";
import useFeedback from "../../../../hooks/useFeedback";

const ActivitiesUpdate = () => {
  const { state } = useLocation();
  const { activity } = state;
  const { setFeedback } = useFeedback()

  const viewHandlers = {
    updateActivitySuccess(data) {
      console.log("<<<<<<UPDATE>>>>>>>", data.statusText);
      setFeedback({
        open: true,
        error: false,
        message: 'Actividad actualizada con éxito'
      })
    },
    updateActivityError(err) {
      console.log("<<<<<<UPDATE ERROR>>>>>>>", err);
      setFeedback({
        open: true,
        error: true,
        message: 'No se pudo actualizar la actividad'
      })
    },
  };

  const presenter = getActivitiesUpdatePresenter(viewHandlers);

  useEffect(() => {
    presenter.present();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const update = (data) => {
    presenter.updateActivity(data);
  };

  return (
    <div className="activity-update-screen">
      <Box className="activity-update-main-container">
        <div className="activity-update-title-container">
          <Typography
            variant="h3"
            gutterBottom
            className="activity-update-title"
          >
            Nueva Actividad
          </Typography>
          <Divider variant="middle" className="separator" />
        </div>
        <div className="activity-update-form-container">
          <ActivitiesForm activity={activity} update={update} />
        </div>
      </Box>
    </div>
  );
};

export default ActivitiesUpdate;
