export const ActivitiesDetailPresenter = (
  getImagesGateway,
  getActivityGateway,
  viewHandlers
) => {
  return {
    present() {
      console.log("Activities detail was rendered!");
    },

    getImages(id) {
      const getImagesPromise = getImagesGateway.getImages(id);

      getImagesPromise
        .then((data) => {
          viewHandlers.getImagesSuccess(data);
        })
        .catch((err) => {
          viewHandlers.getImagesError(err);
        });
    },
    getActivity(id) {
      const getActivityPromise = getActivityGateway.getActivity(id);

      getActivityPromise
        .then((data) => {
          viewHandlers.getActivitySuccess(data);
        })
        .catch((error) => {
          viewHandlers.getActivityError(error);
        });
    },
  };
};
