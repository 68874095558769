const ClientNavbar = {
  role: "visitor",
  routes: [
    {
      title: "Inicio",
      url: "/",
    },
    {
      title: "Nosotros",
      url: "/about",
    },
    {
      title: "Contacto",
      url: "/contact",
    },
    {
      title: "Paquetes",
      url: "/packages",
    },
  ],
};

const AdminNavbar = {
  role: "ADMIN",
  routes: [
    {
      title: "Inicio",
      url: "",
    },
    {
      title: "Paquetes",
      url: "/packages/admin",
    },
    {
      title: "Actividades",
      url: "/activities",
    },
    {
      title: "Mensajes",
      url: "/messages",
    },
  ],
};

export const Navs = [AdminNavbar, ClientNavbar];
