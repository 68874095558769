import { HttpRegisterGateway } from "../gateways/HttpRegisterGateway";
import { HttpLoginGateway } from "../gateways/HttpLoginGateway";
import { HttpResetPasswordGateway } from "../gateways/HttpResetPasswordGateway";
import { HttpChangePasswordGateway } from "../gateways/HttpChangePasswordGateway";
import { HttpIsAdminGateway } from "../gateways/HttpIsAdminGateway";

import { RegisterPresenter } from "./RegisterPresenter";
import { LoginPresenter } from "./LoginPresenter";
import { ResetPasswordPresenter } from "./ResetPasswordPresenter";
import { ChangePasswordPresenter } from "./ChangePasswordPresenter";

export const getRegisterPresenter = (viewHandlers) => {
  const getRegisterGateway = HttpRegisterGateway();
  const getIsAdminGateway = HttpIsAdminGateway();
  return RegisterPresenter(getRegisterGateway, getIsAdminGateway, viewHandlers);
};

export const getLoginPresenter = (viewHandlers) => {
  const getLoginGateway = HttpLoginGateway();
  const getIsAdminGateway = HttpIsAdminGateway();
  return LoginPresenter(getIsAdminGateway, getLoginGateway, viewHandlers);
};

export const getResetPasswordPresenter = (viewHandlers) => {
  const getResetPasswordGateway = HttpResetPasswordGateway();
  const getIsAdminGateway = HttpIsAdminGateway();
  return ResetPasswordPresenter(
    getResetPasswordGateway,
    getIsAdminGateway,
    viewHandlers
  );
};

export const getChangePasswordPresenter = (viewHandlers) => {
  const getChangePasswordGateway = HttpChangePasswordGateway();
  return ChangePasswordPresenter(getChangePasswordGateway, viewHandlers);
};
