import React, { useEffect } from "react";
import "./styles.scss";
import RegisterForm from "../../components/RegisterForm";
import useUser from "../../../../hooks/useUser";
import { getRegisterPresenter } from "../../infrastructure/presentation/presenterProvider";
import Swal from "sweetalert2/src/sweetalert2.js";
import logoAlert from "../../../../assets/images/test.png";
import { useNavigate } from "react-router-dom";
import useFeedback from "../../../../hooks/useFeedback";

const Register = () => {
  const { isLogged } = useUser();
  const navigate = useNavigate();
  const { setFeedback } = useFeedback()

  const viewHandlers = {
    isAdmin(data) {
      console.log("<<<<<<isAdmin>>>>>>>", data.data.message);
      Swal.fire({
        title: `Bienvenido`,
        confirmButtonText: "Ingresar",
        customClass: {
          title: "admin-pass-title",
          confirmButton: "admin-pass-button",
        },
      });
    },
    isNotAdmin(err) {
      console.log("<<<<<<isAdmin>>>>>>>", err);
      Swal.fire({
        title: `Contraseña incorrecta`,
        confirmButtonText: "Aceptar",
        customClass: {
          title: "admin-pass-title",
          confirmButton: "admin-pass-button",
        },
      });
      setTimeout(() => {
        Swal.close();
        navigate("/");
      }, 2000);
    },
    registerSuccess(data) {
      console.log("<<<<<<REGISTER>>>>>>>", data.data.message);
      setFeedback({
        open: true,
        error: false,
        message: 'Cuenta creada con éxito'
      })
    },
    registerError(err) {
      console.log("<<<<<<REGISTER ERROR>>>>>>>", err.response.data.message);
      setFeedback({
        open: true,
        error: true,
        message: 'No se pudo crear la cuenta'
      })
    },
  };

  const presenter = getRegisterPresenter(viewHandlers);

  useEffect(() => {
    presenter.present();
    if (!isLogged) {
      Swal.fire({
        inputLabel: "Ingrese su contraseña",
        input: "password",
        confirmButtonText: "Confirmar",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        inputAttributes: {
          autocapitalize: "off",
          autocorrect: "off",
        },
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        customClass: {
          inputLabel: "admin-pass-title",
          image: "admin-pass-image",
          htmlContainer: "admin-pass-text",
          input: "admin-pass-input",
          confirmButton: "admin-pass-button",
          cancelButton: "admin-pass-button",
        },
        imageUrl: logoAlert,
        imageAlt: "logo-mn",
        preConfirm: (value) => {
          presenter.isAdmin(value);
        },
      }).then((result) => {
        if (result.dismiss === Swal.DismissReason.cancel) {
          navigate(-1);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRegister = (data) => {
    presenter.register(data);
  };

  return (
    <div className="register-screen-main-container">
      <div className="register-screen-title-container">
        <h2 className="register-screen-title">Crea tu cuenta</h2>
      </div>
      <div className="register-screen-form-container">
        <RegisterForm handleRegister={handleRegister} />
      </div>
    </div>
  );
};

export default Register;
