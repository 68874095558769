import axios from "axios";

export const HttpDeletePackageGateway = () => {
  return {
    deletePackage: async (id) => {
      return axios.delete(
        `${process.env.REACT_APP_BASE_URL}/packages/delete/id/${id}`
      );
    },
  };
};
