import React, { useEffect, useState } from "react";
import "./styles.scss";
import ActionButtons from "../../components/ActionButtons";
import {
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import TableToolbar from "../../components/TableToolbar";
import MessageModal from "../../components/MessagesModal";
import { getMessagesHomePresenter } from "../../infrastructure/presentation/presenterProvider";

const MessagesHome = () => {
  const [messages, setMessages] = useState([]);
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState({});
  const [refresh, setRefresh] = useState(false);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      headerClassName: "messages-table-col-header",
      headerAlign: "center",
      width: 40,
    },
    {
      field: "name",
      headerName: "Nombre",
      headerClassName: "messages-table-col-header",
      headerAlign: "center",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      headerClassName: "messages-table-col-header",
      headerAlign: "center",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Estado",
      headerClassName: "messages-table-col-header",
      headerAlign: "center",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        if (params.value === "new") {
          return "Nuevo";
        } else if (params.value === "seen") {
          return "Visto";
        } else if (params.value === "answered") {
          return "Contestado";
        } else if (params.value === "cancelled") {
          return "Cancelado";
        } else if (params.value === "deleted") {
          return "Eliminado";
        }
      },
    },
    {
      field: "created_at",
      headerName: "Fecha",
      headerClassName: "messages-table-col-header",
      headerAlign: "center",
      minWidth: 80,
      flex: 1,
      renderCell: (params) => {
        return new Date(params.value).toLocaleDateString();
      },
    },
    {
      field: "",
      headerName: "Acciones",
      headerClassName: "messages-table-col-header",
      headerAlign: "center",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        return (
          <ActionButtons
            message={params.row}
            setShow={setShow}
            show={show}
            setMessage={setMessage}
          />
        );
      },
    },
  ];

  const viewHandlers = {
    getAllMessagesSuccess(data) {
      console.log("<<<<<<MESSAGES>>>>>>>", data.statusText);
      setMessages(data.data.data);
    },
    getAllMessagesError(err) {
      console.log("<<<<<<MESSAGES ERROR>>>>>>>", err);
    },
    deleteMessageSuccess(data) {
      console.log("<<<<<<MESSAGES DELETE>>>>>>>", data.statusText);
    },
    deleteMessageError(err) {
      console.log("<<<<<<MESSAGES DELETE ERROR>>>>>>>", err);
    },
  };

  const presenter = getMessagesHomePresenter(viewHandlers);

  useEffect(() => {
    presenter.present();
    presenter.getMessages();
    return () => {
      setTimeout(() => {
        setRefresh(false);
      }, 1000);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const onDelete = (id) => {
    presenter.deleteMessage(id);
    setRefresh(true);
  };

  return (
    <div className="messages-home-screen">
      <div className="messages-home-title-container">
        <Typography variant="h4" gutterBottom className="messages-home-title">
          Mensajes
        </Typography>
      </div>
      <div className="messages-home-table-container">
        <DataGrid
          className="messages-table"
          autoPageSize
          disableColumnFilter
          disableColumnSelector
          columns={columns}
          rows={messages && messages}
          slots={{ toolbar: TableToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </div>
      {show && (
        <MessageModal
          show={show}
          setShow={setShow}
          obj={message}
          action={onDelete}
        >
          <div className="delete-messages-modal-title-container">
            <DialogTitle
              id="alert-dialog-title"
              className="delete-messages-modal-title"
            >
              Eliminar definitivamente
            </DialogTitle>
          </div>
          <div className="delete-messages-modal-content">
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                ¿Está seguro que desea eliminar definitivamente este registro?
              </DialogContentText>
              <DialogContentText id="alert-dialog-description">
                Luego de confirmar esta acción, no se podrá deshacer
              </DialogContentText>
            </DialogContent>
          </div>
        </MessageModal>
      )}
    </div>
  );
};

export default MessagesHome;
//TODO hacer el formulario para crear mensajes, buscar la forma meterle un captcha, y mandarle toda la lógica
//TODO también hay que mostrar el formulario en el update, pero todo con readOnly y poner ahí la opción para cambiarle el estado
