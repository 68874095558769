import React, { useState } from "react";
import "./styles.scss";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from "@mui/material";
import Loader from "../../../../components/UI/loader";

const DeleteModal = ({ open, setOpen, activity, deleteActivity }) => {
  const [loading, setLoading] = useState(false);

  const deny = () => {
    setOpen(false);
  };

  const showLoader = () => {
    deleteActivity(activity.id);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 2000);
  };

  return (
    <div className="delete-modal-container">
      <Dialog
        disableAutoFocus
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="delete-modal"
      >
        {!loading ? (
          <>
            <div className="delete-modal-title-container">
              <DialogTitle
                id="alert-dialog-title"
                className="delete-modal-title"
              >
                Ocultar actividad
              </DialogTitle>
            </div>
            <div className="delete-modal-content">
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  ¿Está seguro que desea ocultar este registro?
                </DialogContentText>
                <DialogContentText id="alert-dialog-description" sx={{ mt: 2 }}>
                  Esta acción solo hará que la actividad no sea visible para los visitantes, pero eliminará todas las imágenes asociadas
                </DialogContentText>
              </DialogContent>
            </div>
            <div className="delete-modal-buttons-container">
              <DialogActions className="stack-container">
                <Stack
                  direction="row"
                  spacing={4}
                  className="dialog-buttons-stack"
                >
                  <Button onClick={() => deny()} className="cancel-button">
                    Cancelar
                  </Button>
                  <Button
                    onClick={() => showLoader()}
                    className="accept-button"
                  >
                    Aceptar
                  </Button>
                </Stack>
              </DialogActions>
            </div>
          </>
        ) : (
          <>
            <div className="delete-modal-title-container">
              <DialogTitle
                id="alert-dialog-title"
                className="delete-modal-title"
              >
                Eliminando...
              </DialogTitle>
            </div>
            <div className="delete-modal-content">
              <DialogContent>
                <div className="delete-modal-loader-container">
                  <Loader />
                </div>
              </DialogContent>
            </div>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default DeleteModal;
