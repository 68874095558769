import axios from "axios";

export const HttpGetMessageGateway = () => {
  return {
    getOneMessage: async (id) => {
      return axios.get(
        `${process.env.REACT_APP_BASE_URL}/contact/detail/id/${id}`
      );
    },
  };
};
