import React from "react";
import "./styles.scss";
import {
  Box,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
} from "@mui/material";
import { Controller } from "react-hook-form";

const CustomTextInput = ({
  name,
  control,
  label,
  rules,
  type,
  readOnly,
  multiline,
  rows,
  placeholder,
  endAdornment,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Box className="customTextInput-main-container">
          <FormControl
            className="customTextInput-inner-container"
            fullWidth
            error={error !== undefined}
          >
            <InputLabel
              htmlFor="customTextInput-input"
              className={error ? "" : "customTextInput-label"}
              sx={{ fontWeight: 500 }}
            >
              {label}
            </InputLabel>
            <Input
              type={type}
              readOnly={readOnly}
              multiline={multiline}
              rows={multiline && rows}
              id="customTextInput-input"
              aria-describedby="helper"
              className="customTextInput-input"
              onChange={onChange}
              value={value}
              placeholder={placeholder}
              endAdornment={endAdornment}
            ></Input>
            <FormHelperText className="customTextInput-helper-text" id="helper">
              {error ? error.message : null}
            </FormHelperText>
          </FormControl>
        </Box>
      )}
    />
  );
};

export default CustomTextInput;
