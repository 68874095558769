import PrivateRoute from "../utils/navigation";
import { Roles } from "./roles";

/* Activities */
import ActivitiesHome from "../modules/Activities/views/Home";
import ActivitiesCreate from "../modules/Activities/views/Create";
import ActivitiesUpdate from "../modules/Activities/views/Update";
import UploadImages from "../modules/Activities/views/UploadImages";

/* Messages */
import MessagesHome from "../modules/Messages/views/Home";
import MessagesDetail from "../modules/Messages/views/Detail";

/* Packages */
import AdminPackagesHome from "../modules/PackagesAdmin/views/Home";
import PackagesDetail from "../modules/PackagesAdmin/views/Detail";

export const ACTIVITIES_HOME = {
  path: "/activities",
  element: (
    <PrivateRoute
      path="/activities"
      roles={[Roles.Admin]}
      Component={ActivitiesHome}
    />
  ),
};

export const ACTIVITIES_CREATE = {
  path: "/activities/new",
  element: (
    <PrivateRoute
      path="/activities/new"
      roles={[Roles.Admin]}
      Component={ActivitiesCreate}
    />
  ),
};

export const ACTIVITIES_UPDATE = {
  path: "/activities/update/:id",
  element: (
    <PrivateRoute
      path="/activities/update/:id"
      roles={[Roles.Admin]}
      Component={ActivitiesUpdate}
    />
  ),
};

export const ACTIVITIES_ADD_IMAGES = {
  path: "/activities/upload/:id",
  element: (
    <PrivateRoute
      path="/activities/upload/:id"
      roles={[Roles.Admin]}
      Component={UploadImages}
    />
  ),
};

export const MESSAGES_HOME = {
  path: "/messages",
  element: (
    <PrivateRoute
      path="/messages"
      roles={[Roles.Admin]}
      Component={MessagesHome}
    />
  ),
};

export const MESSAGES_DETAIL = {
  path: "/messages/detail/:id",
  element: (
    <PrivateRoute
      path="/messages/detail/:id"
      roles={[Roles.Admin]}
      Component={MessagesDetail}
    />
  ),
};

export const PACKAGES_ADMIN = {
  path: "/packages/admin",
  element: (
    <PrivateRoute
      path="/packages/admin"
      roles={[Roles.Admin]}
      Component={AdminPackagesHome}
    />
  ),
};

export const PACKAGES_DETAIL = {
  path: "/packages/detail/:id",
  element: (
    <PrivateRoute
      path="/packages/detail/:id"
      roles={[Roles.Admin]}
      Component={PackagesDetail}
    />
  ),
};
