import React from "react";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { IconButton, Stack, Tooltip } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";

const ActionButtons = ({
  selectedPackage,
  setOpen,
  open,
  setSelectedPackage,
}) => {
  const navigate = useNavigate();
  const { id } = selectedPackage;

  const toDetail = () => {
    navigate(`/packages/detail/${id}`);
  };

  const toDelete = () => {
    setOpen(!open);
    setSelectedPackage(selectedPackage);
  };

  return (
    <Stack
      direction={"row"}
      spacing={{ xs: 2, sm: 2, md: 4, lg: 4 }}
      className="action-buttons-stack"
    >
      <Tooltip
        title="Ver detalle"
        placement="top"
        className="action-buttons-tooltips"
      >
        <IconButton className="action-buttons-icon" onClick={toDetail}>
          <VisibilityIcon fontSize="medium" />
        </IconButton>
      </Tooltip>
      <Tooltip
        title="Eliminar"
        placement="top"
        className="action-buttons-tooltips"
      >
        <IconButton className="action-buttons-icon" onClick={toDelete}>
          <DeleteIcon fontSize="medium" />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

export default ActionButtons;
