import React, { useEffect } from "react";
import "./styles.scss";
import { useBagagge } from "../../../../hooks/useBagagge";
import { Divider, Typography } from "@mui/material";
import ClientForm from "../../components/ClientForm";
import { useStepper } from "../../../../hooks/useStepper";

const ClientData = () => {
  const { client, setClient, handleDisabled } = useBagagge();
  const { handleBack, handleNext } = useStepper();

  const goBack = () => {
    handleBack();
  };

  const submitClient = (data) => {
    setClient(data);
    handleNext();
  };

  useEffect(() => {
    if (Object.keys(client).length !== 0) {
      handleDisabled(false);
    }
    return () => handleDisabled(true);
  }, [handleDisabled, client]);

  return (
    <div className="client-data-screen">
      <div className="client-data-screen-form-container">
        <div className="client-data-screen-title">
          <Typography variant="h5" gutterBottom className="client-data-title">
            Unos últimos datos...
          </Typography>
        </div>
        <Divider variant="middle" />
        <ClientForm
          submitClient={submitClient}
          goBack={goBack}
          client={client}
        />
      </div>
    </div>
  );
};

export default ClientData;
