import axios from "axios";

export const HttpChangePasswordGateway = () => {
  return {
    changePass: async (data) => {
      const { password, confirmPassword, token } = data;
      const resetToken = token;
      const body = {
        newPassword: password,
        confirmPassword,
      };
      return axios.patch(
        `${process.env.REACT_APP_BASE_URL}/reset-password/verify-token/${resetToken}`,
        body
      );
    },
  };
};
