import React, { useEffect } from "react";
import "./styles.scss";
import PackageForm from "../../components/PackageForm";
import { useBagagge } from "../../../../hooks/useBagagge";
import { useStepper } from "../../../../hooks/useStepper";

const PackagesData = () => {
  const { stay, setStay, handleDisabled } = useBagagge();
  const { handleBack, handleNext } = useStepper();

  const goBack = () => {
    /* Acá en el form se podría implementar un reset del formulario, consultar */
    handleBack();
  };

  const confirmStay = (data) => {
    const body = {
      from_date: data.from_date.$d,
      to_date: data.to_date.$d,
      people: Number(data.people),
      consultation: data.consultation,
    };
    setStay(body);
    handleNext();
  };

  useEffect(() => {
    if (Object.keys(stay).length !== 0) {
      handleDisabled(false);
    }
    return () => handleDisabled(true);
  }, [handleDisabled, stay]);

  return (
    <div className="package-data-screen">
      <div className="package-data-screen-form-container">
        <PackageForm confirmStay={confirmStay} stay={stay} goBack={goBack} />
      </div>
    </div>
  );
};

export default PackagesData;
