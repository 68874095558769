import React, { useEffect } from "react";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Input,
  InputLabel,
  Stack,
  Switch,
} from "@mui/material";

const ActivitiesForm = ({ create, update, activity }) => {
  const navigate = useNavigate();
  const { handleSubmit, control, setValue } = useForm({
    defaultValues: {
      name: "",
      description: "",
      location: "",
      duration: "",
      availability: activity ? activity.availability : false,
    },
  });

  const submitData = (data) => {
    activity === undefined
      ? create(data)
      : update({ ...data, id: activity.id });
    navigate("/activities");
  };

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (activity !== undefined) {
      setValue("name", activity.name);
      setValue("description", activity.description);
      setValue("location", activity.location);
      setValue("duration", Number(activity.duration));
      setValue("availability", activity.availability);
    }
  }, [activity, setValue]);

  return (
    <div className="activities-form-main-container">
      <Grid container spacing={2} className="activities-form-main-grid">
        {/* nombre */}
        <Grid item xs={12} md={12} lg={12} className="activities-form-item">
          <Controller
            name="name"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Campo obligatorio",
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Box className="form-input-box">
                <FormControl className="activities-form-control" fullWidth>
                  <InputLabel
                    htmlFor="form-name-input"
                    error={error !== undefined}
                  >
                    Nombre
                  </InputLabel>
                  <Input
                    id="form-name-input"
                    color="custom"
                    aria-describedby="form-name-helper"
                    type="text"
                    className="form-name-input"
                    onChange={onChange}
                    value={value}
                    placeholder="Nombre de la actividad"
                    error={error !== undefined}
                  />
                  <FormHelperText
                    id="form-name-helper"
                    className="inputs-helper"
                    error={error !== undefined}
                  >
                    {error ? error.message : null}
                  </FormHelperText>
                </FormControl>
              </Box>
            )}
          />
        </Grid>
        {/* ubicación */}
        <Grid item xs={12} md={12} lg={12} className="activities-form-item">
          <Controller
            name="location"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Campo obligatorio",
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Box className="form-input-box">
                <FormControl className="activities-form-control" fullWidth>
                  <InputLabel
                    htmlFor="form-location-input"
                    error={error !== undefined}
                  >
                    Ubicación
                  </InputLabel>
                  <Input
                    id="form-location-input"
                    color="custom"
                    aria-describedby="form-location-helper"
                    type="text"
                    className="form-location-input"
                    onChange={onChange}
                    value={value}
                    placeholder="Lugar donde se realiza la actividad"
                    error={error !== undefined}
                  />
                  <FormHelperText
                    id="form-location-helper"
                    className="inputs-helper"
                    error={error !== undefined}
                  >
                    {error ? error.message : null}
                  </FormHelperText>
                </FormControl>
              </Box>
            )}
          />
        </Grid>
        {/* Duración */}
        <Grid item xs={12} md={6} lg={6} className="activities-form-item">
          <Controller
            name="duration"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Campo obligatorio",
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Box className="form-input-box">
                <FormControl className="activities-form-control" fullWidth>
                  <InputLabel
                    htmlFor="form-duration-input"
                    error={error !== undefined}
                  >
                    Duración (hs)
                  </InputLabel>
                  <Input
                    id="form-duration-input"
                    color="custom"
                    aria-describedby="form-duration-helper"
                    type="number"
                    className="form-duration-input"
                    onChange={onChange}
                    value={value}
                    placeholder="Tiempo en horas que tarda en desarrollarse la actividad"
                    error={error !== undefined}
                  />
                  <FormHelperText
                    id="form-duration-helper"
                    className="inputs-helper"
                    error={error !== undefined}
                  >
                    {error ? error.message : null}
                  </FormHelperText>
                </FormControl>
              </Box>
            )}
          />
        </Grid>
        {/* disponibilidad */}
        <Grid item xs={12} md={6} lg={6} className="activities-form-item">
          <Controller
            name="availability"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Box className="form-input-box">
                <FormControl className="activities-form-control" fullWidth>
                  <div className="form-switch-container">
                    <FormLabel htmlFor="switch">Disponibilidad</FormLabel>
                    <FormControlLabel
                      control={
                        <Switch
                          id="switch"
                          value={value}
                          defaultChecked={activity && activity.availability}
                          onChange={(event, val) => {
                            if (val) {
                              setValue("availability", false);
                            }
                            return onChange(val);
                          }}
                        />
                      }
                    />
                  </div>
                </FormControl>
              </Box>
            )}
          />
        </Grid>
        {/* Descripción */}
        <Grid item xs={12} md={12} lg={12} className="activities-form-item">
          <Controller
            name="description"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Campo obligatorio",
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Box className="form-input-box">
                <FormControl className="activities-form-control" fullWidth>
                  <InputLabel
                    htmlFor="form-description-input"
                    error={error !== undefined}
                  >
                    Descripción
                  </InputLabel>
                  <Input
                    id="form-description-input"
                    color="custom"
                    aria-describedby="form-description-helper"
                    type="text"
                    multiline
                    rows={4}
                    className="form-description-input"
                    onChange={onChange}
                    value={value}
                    placeholder="Descripción de la actividad"
                    error={error !== undefined}
                  />
                  <FormHelperText
                    id="form-description-helper"
                    className="inputs-helper"
                    error={error !== undefined}
                  >
                    {error ? error.message : null}
                  </FormHelperText>
                </FormControl>
              </Box>
            )}
          />
        </Grid>
      </Grid>
      <div className="activities-form-buttons-container">
        <Stack
          direction={{
            xs: "column-reverse",
            sm: "column-reverse",
            md: "row",
            lg: "row",
          }}
          spacing={4}
          className="buttons-stack"
        >
          <Button variant="outlined" className="back-button" onClick={goBack}>
            Volver
          </Button>
          <Button
            variant="outlined"
            className="submit-button"
            onClick={handleSubmit(submitData)}
          >
            {activity ? "Editar" : "Crear"}
          </Button>
        </Stack>
      </div>
    </div>
  );
};

export default ActivitiesForm;
