import React from "react";
import "./styles.scss";
import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import logo from "../../../../assets/images/2-png.png";
import { useNavigate } from "react-router-dom";
import useUser from "../../../../hooks/useUser";
import { Navs } from "../../services/nav.services";

const CustomDrawer = ({ setOpen }) => {
  const { isLogged, logout, user } = useUser();
  const navigate = useNavigate();
  const handleDrawerToggle = () => {
    setOpen((prevState) => !prevState);
  };

  const navItems = user
    ? Navs.find((item) => item.role === user.role)
    : Navs.find((item) => item.role === "visitor");

  return (
    <div className="customDrawer-main-container">
      <Box
        onClick={handleDrawerToggle}
        sx={{ textAlign: "center" }}
        className="customDrawer-main-box"
      >
        <div className="customDrawer-header">
          <IconButton className="customDrawer-close-button">
            <div className="customDrawer-close-button-content-container">
              <img
                src={logo}
                alt=""
                className="customDrawer-close-button-logo"
              />
            </div>
          </IconButton>
        </div>
        <Divider />
        <List className="customDrawer-list-container">
          {navItems.routes.map((item, index) => (
            <ListItem key={index} className="customDrawer-list-item">
              <ListItemButton
                sx={{ textAlign: "center" }}
                className="customDrawer-list-item-button"
                onClick={() => navigate(`${item.url}`)}
              >
                <ListItemText primary={item.title} />
              </ListItemButton>
            </ListItem>
          ))}
          {isLogged && (
            <ListItem className="customDrawer-list-item">
              <ListItemButton
                sx={{ textAlign: "center" }}
                className="customDrawer-list-item-button"
                onClick={logout}
              >
                <ListItemText primary={"Logout"} />
              </ListItemButton>
            </ListItem>
          )}
        </List>
      </Box>
    </div>
  );
};

export default CustomDrawer;
