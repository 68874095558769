import React, { useState } from "react";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import LuggageIcon from "@mui/icons-material/Luggage";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  Avatar,
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { useBagagge } from "../../../hooks/useBagagge";
import { useStepper } from "../../../hooks/useStepper";

const Backpack = () => {
  const [open, setOpen] = useState(false);
  const { bagagge, removeFromBagagge, clearBagagge, setClient, setStay } =
    useBagagge();
  const { reset, handleNext } = useStepper();
  const navigate = useNavigate();

  const removeActivity = (e, activity) => {
    e.stopPropagation();
    if (bagagge.length === 1) {
      reset();
    }
    removeFromBagagge(activity);
  };

  const clearBackpack = () => {
    clearBagagge([]);
    setClient({});
    setStay({});
    reset();
  };

  const toggleBackpack = () => {
    setOpen(!open);
  };

  const toDetail = (activity) => {
    navigate(`/activities/detail/${activity.id}`, { state: { activity } });
    setOpen(!open);
  };

  const handleNextStep = () => {
    navigate('/packages')
    handleNext();
    setOpen(!open)
  }

  return (
    <>
      <div className="backpack-btn-container">
        <IconButton onClick={toggleBackpack} className="backpack-btn">
          <LuggageIcon />
        </IconButton>
      </div>

      <Drawer
        anchor="right"
        open={open}
        onClose={toggleBackpack}
        className="backpack-drawer"
      >
        <div className="backpack-list-container">
          <Box
            className="backpack-inner-container"
            sx={{
              minWidth: 270,
              maxWidth: 300,
              backgroundColor: "#eeeeee",
            }}
          >
            <div className="backpack-title-container">
              <Typography variant="overline" className="backpack-title">
                Mi equipaje
              </Typography>
            </div>
            <List className="backpack-list">
              {bagagge.map((activity) => (
                <ListItem
                  onClick={() => toDetail(activity)}
                  key={activity.id}
                  className="backpack-list-item"
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={(e) => removeActivity(e, activity)}
                    >
                      <DeleteIcon className="backpack-action-btn" />
                    </IconButton>
                  }
                >
                  <ListItemAvatar>
                    <Avatar>
                      <Avatar
                        alt={`activity-img`}
                        src={`${activity?.images[0]?.url}`}
                        className="backpack-list-img"
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={activity.name}
                    className="backpack-list-item-text"
                  />
                </ListItem>
              ))}
            </List>
            <footer className="backpack-footer">
              <Button
                className="backpack-clear-btn"
                // endIcon={<DeleteForeverIcon />}
                onClick={handleNextStep}
              >
                Paso siguiente
              </Button>
              <Button
                className="backpack-clear-btn"
                endIcon={<DeleteForeverIcon />}
                onClick={clearBackpack}
              >
                Vaciar el equipaje
              </Button>
            </footer>
          </Box>
        </div>
      </Drawer>
    </>
  );
};

export default Backpack;
