import React from "react";
import "./styles.scss";
import { Step, StepLabel, Stepper, Typography } from "@mui/material";
import ActivitiesGrid from "../ActivitiesGrid";
import PackagesData from "../PackagesData";
import ClientData from "../ClientData";
import { useStepper } from "../../../../hooks/useStepper";
import Checkout from "../Checkout";

const steps = [
  "Seleccione actividades",
  "Información de estadía",
  "Información de contacto",
];

const stepsDesc = [<ActivitiesGrid />, <PackagesData />, <ClientData />];

const PackagesHome = () => {
  const { activeStep, completed } = useStepper();

  const totalSteps = steps.length;
  const completedSteps = Object.keys(completed).length;
  const allStepsCompleted = completedSteps === totalSteps;

  return (
    <div className="packages-screen">
      <div className="packages-screen-title-container">
        <Typography variant="h5" className="packages-screen-title" gutterBottom>
          Crea tu propio paquete personalizado
        </Typography>
      </div>
      <div className="packages-stepper-container">
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div className="packages-screen-views-container">
          {allStepsCompleted ? (
            <>
              <div className="package-screen-childrens">
                <Checkout />
              </div>
            </>
          ) : (
            <>
              <div className="package-screen-childrens">
                {stepsDesc[activeStep]}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PackagesHome;
