export const CheckoutPresenter = (viewHandlers, createPackageGateway) => {
  return {
    present() {
      console.log("Checkout screen was rendered!");
    },
    createPackage(data) {
      const createPackagePromise = createPackageGateway.create(data);

      createPackagePromise
        .then((data) => {
          viewHandlers.createPackageSuccess(data);
        })
        .catch((err) => {
          viewHandlers.createPackageError(err);
        });
    },
  };
};
