import React, { useRef, useState } from "react";
import "./styles.scss";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Box, Button, Grid, Stack } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import CustomTextInput from "../../../../components/UI/CustomTextInput";

const ContactForm = ({ createNewMessage }) => {
  const captcha = useRef();
  const [valid, setValid] = useState(false);
  const { handleSubmit, control } = useForm();
  const navigate = useNavigate();

  const onSubmitForm = (data) => {
    createNewMessage(data);
  };

  const onCaptchaChange = () => {
    if (captcha.current.getValue()) {
      setValid(true);
    }
  };

  return (
    <div className="contact-form-main-container">
      <Box className="contact-form-grid-container">
        <Grid container spacing={2} className="contact-form-grid">
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            className="contact-form-grid-item"
          >
            <CustomTextInput
              name="name"
              control={control}
              label={"Nombre *"}
              type={"text"}
              placeholder={"Nombre"}
              rules={{
                required: {
                  value: true,
                  message: "Campo obligatorio",
                },
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            className="contact-form-grid-item"
          >
            <CustomTextInput
              name="lastname"
              control={control}
              label={"Apellido *"}
              type={"text"}
              placeholder={"Apellido"}
              rules={{
                required: {
                  value: true,
                  message: "Campo obligatorio",
                },
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            className="contact-form-grid-item"
          >
            <CustomTextInput
              name="email"
              control={control}
              label={"Email *"}
              type={"email"}
              placeholder={"username@example.com"}
              rules={{
                required: {
                  value: true,
                  message: "Campo obligatorio",
                },
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@”]+(\.[^<>()[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/,
                  message: "El correo ingresado no es válido",
                },
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            className="contact-form-grid-item"
          >
            <CustomTextInput
              name="phone"
              control={control}
              label={"Teléfono *"}
              type={"number"}
              placeholder={"0000000000"}
              rules={{
                required: {
                  value: true,
                  message: "Campo obligatorio",
                },
                pattern: {
                  value: /^([0-9]{10,14})$/,
                  message: "El número ingresado no es válido",
                },
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className="contact-form-grid-item"
          >
            <CustomTextInput
              name="consultation"
              control={control}
              label={"Consulta"}
              type={"text"}
              multiline={true}
              rows={5}
              placeholder={"Su consulta..."}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className="contact-form-grid-item"
          >
            <ReCAPTCHA
              ref={captcha}
              sitekey={process.env.REACT_APP_SITE_KEY}
              onChange={onCaptchaChange}
            />
          </Grid>
        </Grid>
      </Box>
      <div className="contact-form-buttons-container">
        <Stack
          spacing={{ xs: 4, md: 6 }}
          direction={{ xs: "column-reverse", sm: "column-reverse", md: "row" }}
          className="contact-form-stack"
        >
          <Button
            variant="outlined"
            className="contact-form-back-btn"
            onClick={() => navigate(-1)}
          >
            Volver
          </Button>
          <Button
            variant="outlined"
            className="contact-form-submit-btn"
            onClick={handleSubmit(onSubmitForm)}
            disabled={!valid}
          >
            Enviar
          </Button>
        </Stack>
      </div>
    </div>
  );
};

export default ContactForm;
