import React, { createContext, useState } from "react";

const BagaggeContext = createContext();

const BagaggeContextProvider = ({ children }) => {
  const [bagagge, setBagagge] = useState([]);
  const [stay, setStay] = useState({});
  const [client, setClient] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);

  const addToBagagge = (activity) => {
    const isInBagagge = bagagge.find(
      (activityOnBagagge) => activityOnBagagge.id === activity.id
    );
    if (!isInBagagge) {
      setBagagge((prevState) => [...prevState, { ...activity }]);
    }
  };

  const removeFromBagagge = (activity) => {
    setBagagge((prevState) =>
      prevState.filter((item) => item.id !== activity.id)
    );
  };

  const checkActivityInBagagge = (activity) => {
    return bagagge.some((item) => item.id === activity.id);
  };

  const clearBagagge = () => {
    setBagagge([]);
  };

  const handleDisabled = (status) => {
    setIsDisabled(status);
  };

  const resetBagagge = () => {
    setBagagge([]);
    setClient({});
    setStay({});
  };

  const data = {
    bagagge,
    addToBagagge,
    removeFromBagagge,
    clearBagagge,
    stay,
    setStay,
    client,
    setClient,
    isDisabled,
    handleDisabled,
    checkActivityInBagagge,
    resetBagagge,
  };

  return (
    <BagaggeContext.Provider value={data}>{children}</BagaggeContext.Provider>
  );
};

export { BagaggeContextProvider };

export default BagaggeContext;
