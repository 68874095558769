import React from 'react'
import './styles.scss'
import { Alert, IconButton, Slide, Snackbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const SlideIn = (props) => {
    return <Slide {...props} direction='right' />
}

const StatusBar = ({ feedback, handleClose }) => {
    return (
        <div className='statusbar-main-container'>
            <Snackbar
                className='statusbar'
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                TransitionComponent={SlideIn}
                transitionDuration={1000}
                open={feedback.open}
                autoHideDuration={4000}
                onClose={handleClose}
                action={
                    <IconButton size='small' aria-label='close-statusbar' color='inherit' onClick={handleClose}>
                        <CloseIcon fontSize='small' />
                    </IconButton>
                }
            >
                <Alert onClose={handleClose} severity={feedback.error ? 'error' : 'success'} sx={{ width: '100%' }}>
                    {feedback.message}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default StatusBar