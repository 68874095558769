import axios from "axios";

export const HttpResetPasswordGateway = () => {
  return {
    verifyEmail: async (data) => {
      const { email } = data;
      const body = {
        email,
      };
      return axios.post(
        `${process.env.REACT_APP_BASE_URL}/reset-password/verify-email`,
        body
      );
    },
  };
};
