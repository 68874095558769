import React from "react";
import "./styles.scss";
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const CheckoutList = ({ data }) => {
  const navigate = useNavigate();

  const toDetail = (id, activity) => {
    navigate(`/activities/detail/${id}`, { state: { activity } });
  };

  return (
    <div className="checkout-list-container">
      {data && data.length > 0 && (
        <List className="checkout-list">
          {data.map((item) => (
            <ListItem
              key={item.id}
              className="checkout-list-item"
              onClick={() => toDetail(item.id, item)}
            >
              <ListItemAvatar>
                <Avatar>
                  <Avatar
                    alt={`activity-img`}
                    src={`${item?.images[0]?.url}`}
                    className="checkout-list-img"
                  />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={item.name} color="#FFF" />
            </ListItem>
          ))}
        </List>
      )}
    </div>
  );
};

export default CheckoutList;
