import axios from "axios";

export const HttpCreateMessageGateway = () => {
  return {
    create: async (data) => {
      const { name, lastname, email, phone, consultation } = data;
      const body = {
        name,
        lastname,
        email,
        phone,
        consultation,
        status: "new",
      };
      return axios.post(
        `${process.env.REACT_APP_BASE_URL}/contact/create`,
        body
      );
    },
  };
};
