import React from "react";
import "./styles.scss";
import {
  Avatar,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PeopleIcon from "@mui/icons-material/People";
import MessageIcon from "@mui/icons-material/Message";

const CheckoutStayGrid = ({ data }) => {
  const { from_date, to_date, people, consultation } = data;
  return (
    <div className="checkout-stay-grid-container">
      <Grid container className="checkout-stay-grid">
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          className="checkout-stay-grid-item"
        >
          <ListItem className="stay-data-item">
            <ListItemAvatar>
              <Avatar>
                <CalendarMonthIcon className="stay-data-icon" />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Desde: "
              secondary={new Date(from_date).toLocaleDateString()}
            />
          </ListItem>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          className="checkout-stay-grid-item"
        >
          <ListItem className="stay-data-item">
            <ListItemAvatar>
              <Avatar>
                <CalendarMonthIcon className="stay-data-icon" />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Hasta: "
              secondary={new Date(to_date).toLocaleDateString()}
            />
          </ListItem>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          className="checkout-stay-grid-item"
        >
          <ListItem className="stay-data-item">
            <ListItemAvatar>
              <Avatar>
                <PeopleIcon className="stay-data-icon" />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Cantidad de personas: " secondary={people} />
          </ListItem>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className="checkout-stay-grid-item"
        >
          <ListItem className="stay-data-item">
            <ListItemAvatar>
              <Avatar>
                <MessageIcon className="stay-data-icon" />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Consulta: " secondary={consultation} />
          </ListItem>
        </Grid>
      </Grid>
    </div>
  );
};

export default CheckoutStayGrid;
