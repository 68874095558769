import React, { useEffect, useState } from "react";
import "./styles.scss";
import { Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CustomToolbar from "../../components/CustomToolbar";
import ActionButtons from "../../components/ActionButtons";
import { getAdminPackagesPresenter } from "../../infrastructure/presentation/PresenterProvider";
import DeleteModal from "../../components/DeleteModal";
import useFeedback from "../../../../hooks/useFeedback";

const AdminPackagesHome = () => {
  const [packages, setPackages] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState({});
  const [refresh, setRefresh] = useState(false);
  const { setFeedback } = useFeedback()

  const columns = [
    {
      field: "id",
      headerName: "ID",
      headerClassName: "admin-packages-col-header",
      headerAlign: "center",
      width: 40,
    },
    {
      field: "people",
      headerName: "N° de personas",
      headerClassName: "admin-packages-col-header",
      headerAlign: "center",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "client.name",
      headerName: "Cliente",
      headerClassName: "admin-packages-col-header",
      headerAlign: "center",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        return `${params.row.client.name} ${params.row.client.lastname}`;
      },
    },
    {
      field: "from_date",
      headerName: "Desde",
      headerClassName: "admin-packages-col-header",
      headerAlign: "center",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        return new Date(params.value).toLocaleDateString();
      },
    },
    {
      field: "to_date",
      headerName: "Hasta",
      headerClassName: "admin-packages-col-header",
      headerAlign: "center",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        return new Date(params.value).toLocaleDateString();
      },
    },
    {
      field: "",
      headerName: "Acciones",
      headerClassName: "admin-packages-col-header",
      headerAlign: "center",
      minWidth: 220,
      flex: 1,
      renderCell: (params) => {
        return (
          <ActionButtons
            selectedPackage={params.row}
            setOpen={setOpen}
            open={open}
            setSelectedPackage={setSelectedPackage}
          />
        );
      },
    },
  ];

  const viewHandlers = {
    getPackagesSuccess(data) {
      console.log("<<<<<<Packages>>>>>>>", data.statusText);
      setPackages(data.data.data);
    },
    getPackagesError(err) {
      console.log("<<<<<<Packages error>>>>>>>", err);
    },
    deletePackageSuccess(data) {
      console.log("<<<<<<Delete>>>>>>>", data.statusText);
      setFeedback({
        open: true,
        error: false,
        message: 'Paquete eliminado'
      })
    },
    deletePackageError(err) {
      console.log("<<<<<<Delete error>>>>>>>", err);
      setFeedback({
        open: true,
        error: true,
        message: 'No se pudo eliminar el paquete'
      })
    },
  };

  const presenter = getAdminPackagesPresenter(viewHandlers);

  useEffect(() => {
    presenter.present();
    presenter.getPackages();
    return () => {
      setTimeout(() => {
        setRefresh(false);
      }, 1000);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  useEffect(() => {
    setRefresh(true);
  }, []);

  const onDelete = (id) => {
    presenter.deletePackage(id);
    setRefresh(true);
  };

  return (
    <div className="admin-packages-screen">
      <div className="admin-packages-title-container">
        <Typography variant="h4" gutterBottom className="admin-packages-title">
          Paquetes
        </Typography>
      </div>
      <div className="admin-packages-table-container">
        <DataGrid
          className="admin-packages-table"
          autoPageSize
          disableColumnFilter
          disableColumnSelector
          columns={columns}
          rows={packages && packages}
          slots={{ toolbar: CustomToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </div>
      {open && (
        <DeleteModal
          open={open}
          setOpen={setOpen}
          selectedPackage={selectedPackage}
          deletePackage={onDelete}
        />
      )}
    </div>
  );
};

export default AdminPackagesHome;
