export const ResetPasswordPresenter = (
  getResetPasswordGateway,
  getIsAdminGateway,
  viewHandlers
) => {
  return {
    present() {
      console.log("Reset password screen was rendered!");
    },
    verifyEmail(data) {
      const verifyEmailPromise = getResetPasswordGateway.verifyEmail(data);

      verifyEmailPromise
        .then((data) => {
          viewHandlers.verifyEmailSuccess(data);
        })
        .catch((err) => {
          viewHandlers.verifyEmailError(err);
        });
    },
    isAdmin(data) {
      const isAdminPromise = getIsAdminGateway.verify(data);

      isAdminPromise
        .then((data) => {
          viewHandlers.isAdmin(data);
        })
        .catch((err) => {
          viewHandlers.isNotAdmin(err);
        });
    },
  };
};
