import React from "react";
import "./styles.scss";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  Avatar,
  Button,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { useBagagge } from "../../../../hooks/useBagagge";
import { useNavigate } from "react-router-dom";
import logo from '../../../../assets/images/1.jpeg'

const ProductCard = ({ activity }) => {
  const { addToBagagge, checkActivityInBagagge } = useBagagge();
  const navigate = useNavigate();

  const addActivity = () => {
    addToBagagge(activity);
    navigate("/packages");
  };

  const isInBagagge = checkActivityInBagagge(activity);

  const seeDetails = (evt) => {
    evt.stopPropagation();
    navigate(`/activities/detail/${activity.id}`);
  };

  return (
    <div className="activity-card">
      <div className="activity-card-img-container">
        {
          activity?.images && activity?.images.length > 0 ?
            (<CardMedia
              sx={{ height: 180 }}
              image={`${activity?.images[0]?.url}`}
              title={"Ver detalles"}
              onClick={(e) => seeDetails(e)}
            />)
            :
            (<CardMedia
              sx={{ height: 180 }}
              image={logo}
              title={"Ver detalles"}
              onClick={(e) => seeDetails(e)}
            />)
        }
      </div>
      <div className="activity-card-body">
        <CardContent className="activity-card-content">
          <div className="activity-card-title-container">
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="activity-card-title"
              sx={{ textOverflow: "ellipsis" }}
            >
              {activity.name}
            </Typography>
          </div>
          <Divider variant="middle" />
          <div className="activity-card-data-container">
            <Stack
              direction={"column"}
              justifyContent={"center"}
              alignItems={"stretch"}
              className="activity-card-data-stack"
            >
              <div className="card-item-container">
                <div className="card-item-content">
                  <Avatar>
                    <LocationOnIcon className="card-item-icon" />
                  </Avatar>
                  <Typography className="card-item-text">
                    <span className="title">Ubicación:</span>
                    {` ${activity.location}`}
                  </Typography>
                </div>
              </div>
              <div className="card-item-container">
                <div className="card-item-content">
                  <Avatar>
                    <AlarmOnIcon className="card-item-icon" />
                  </Avatar>
                  <Typography className="card-item-text">
                    <span className="title">Duración:</span>
                    {` ${activity.duration} horas`}
                  </Typography>
                </div>
              </div>
            </Stack>
          </div>
        </CardContent>
      </div>
      <Divider variant="middle" />
      <div className="activity-card-footer">
        <CardActions className="activity-card-actions">
          <Button
            size="large"
            className="activity-card-buttons"
            onClick={addActivity}
            disabled={isInBagagge}
          >
            Agregar a mi paquete
          </Button>
        </CardActions>
      </div>
    </div>
  );
};

export default ProductCard;
