import React, { useState } from "react";
import "./styles.scss";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from "@mui/material";
import Loader from "../../../../components/UI/loader";

const DeleteModal = ({ open, setOpen, selectedPackage, deletePackage }) => {
  const [loading, setLoading] = useState(false);

  const deny = () => {
    setOpen(false);
  };

  const showLoader = () => {
    deletePackage(selectedPackage.id);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 2000);
  };

  return (
    <div className="package-delete-screen">
      <Dialog
        disableAutoFocus
        open={open}
        aria-labelledby="package-delete-title"
        aria-describedby="package-delete-desc"
        className="package-delete-modal"
      >
        {!loading ? (
          <>
            <div className="package-delete-title-container">
              <DialogTitle
                id="package-delete-title"
                className="package-delete-title"
              >
                Eliminar definitivamente
              </DialogTitle>
            </div>
            <div className="package-delete-content">
              <DialogContent>
                <DialogContentText id="package-delete-desc">
                  ¿Está seguro que desea eliminar definitivamente este registro?
                </DialogContentText>
                <DialogContentText id="package-delete-desc">
                  Luego de confirmar esta acción, no se podrá deshacer
                </DialogContentText>
              </DialogContent>
            </div>
            <div className="delete-package-btns-container">
              <DialogActions className="alert-stack-container">
                <Stack direction="row" spacing={4} className="alert-btn-stack">
                  <Button onClick={() => deny()} className="alert-cancel-btn">
                    Cancelar
                  </Button>
                  <Button
                    onClick={() => showLoader()}
                    className="alert-accept-btn"
                  >
                    Confirmar
                  </Button>
                </Stack>
              </DialogActions>
            </div>
          </>
        ) : (
          <>
            <div className="package-delete-title-container">
              <DialogTitle
                id="package-delete-title"
                className="package-delete-title"
              >
                Eliminando...
              </DialogTitle>
            </div>
            <div className="package-delete-content">
              <DialogContent>
                <div className="package-delete-loader-container">
                  <Loader />
                </div>
              </DialogContent>
            </div>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default DeleteModal;
