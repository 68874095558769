import React, { useState, useEffect } from "react";
import "./styles.scss";
import { Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import ActionButtons from "../../components/ActionButtons";
import CustomToolbar from "../../components/CustomToolbar";
import { getActivitiesHomePresenter } from "../../infrastructure/presentation/presenterProvider";
import DeleteModal from "../../components/DeleteModal";
import useFeedback from "../../../../hooks/useFeedback";

const ActivitiesHome = () => {
  const [activities, setActivities] = useState([]);
  const [open, setOpen] = useState(false);
  const [activity, setActivity] = useState({});
  const [refresh, setRefresh] = useState(false);
  const { setFeedback } = useFeedback()

  const columns = [
    {
      field: "id",
      headerName: "ID",
      headerClassName: "activities-table-col-header",
      headerAlign: "center",
      width: 40,
    },
    {
      field: "name",
      headerName: "Nombre",
      headerClassName: "activities-table-col-header",
      headerAlign: "center",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "location",
      headerName: "Lugar",
      headerClassName: "activities-table-col-header",
      headerAlign: "center",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "duration",
      headerName: "Duración en hs",
      headerClassName: "activities-table-col-header",
      headerAlign: "center",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "availability",
      headerName: "Disponibilidad",
      headerClassName: "activities-table-col-header",
      headerAlign: "center",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        if (params.value === true) {
          return "Disponible";
        } else {
          return "No disponible";
        }
      },
    },
    {
      field: "",
      headerName: "Acciones",
      headerClassName: "activities-table-col-header",
      headerAlign: "center",
      minWidth: 220,
      flex: 1,
      renderCell: (params) => {
        return (
          <ActionButtons
            activity={params.row}
            setOpen={setOpen}
            open={open}
            setActivity={setActivity}
          />
        );
      },
    },
  ];

  const viewHandlers = {
    getActivitiesSuccess(data) {
      console.log("<<<<<<ACTIVITIES>>>>>>>", data.statusText);
      setActivities(data.data.data);
    },
    getActivitiesError(err) {
      console.log("<<<<<<ACTIVITIES ERROR>>>>>>>", err);
    },
    deleteActivitySuccess(data) {
      console.log("<<<<<<DELETE>>>>>>>", data.statusText);
      setFeedback({
        open: true,
        error: false,
        message: 'Actividad eliminada'
      })
    },
    deleteActivityError(err) {
      console.log("<<<<<<DELETE ERROR>>>>>>>", err);
      setFeedback({
        open: true,
        error: true,
        message: 'No se pudo eliminar la actividad'
      })
    },
  };

  const presenter = getActivitiesHomePresenter(viewHandlers);

  useEffect(() => {
    presenter.present();
    presenter.getAllActivities();
    return () => {
      setTimeout(() => {
        setRefresh(false);
      }, 2500);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  useEffect(() => {
    setRefresh(true);
  }, []);

  const onDelete = (id) => {
    presenter.deleteActivity(id);
    setRefresh(true);
  };

  return (
    <div className="activities-home-screen">
      <div className="activities-home-title-container">
        <Typography variant="h4" gutterBottom className="activities-home-title">
          Actividades
        </Typography>
      </div>
      <div className="activities-home-table-container">
        <DataGrid
          className="activities-table"
          autoPageSize
          disableColumnFilter
          disableColumnSelector
          columns={columns}
          rows={activities && activities}
          slots={{ toolbar: CustomToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </div>
      {open && (
        <DeleteModal
          open={open}
          setOpen={setOpen}
          activity={activity}
          deleteActivity={onDelete}
        />
      )}
    </div>
  );
};

export default ActivitiesHome;
