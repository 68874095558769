import axios from "axios";

export const HttpGetActivitiesGateway = () => {
  return {
    getAllActivities: async (data) => {
      const { page, limit } = data;
      return axios.get(
        `${process.env.REACT_APP_BASE_URL}/activities/paginated?page=${page}&limit=${limit}`
      );
    },
  };
};
