import React, { useState } from "react";
import "./styles.scss";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import Loader from "../../../../components/UI/loader";

const MessageModal = ({ show, setShow, obj, action, children }) => {
  const [loading, setLoading] = useState(false);

  const cancel = () => {
    setShow(false);
  };

  const handleShowLoader = () => {
    action(obj.id);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setShow(false);
      /* para probar lo dejo en 1ms, estaba en 2 */
    }, 1000);
  };

  return (
    <div className="messages-modal-main-container">
      <Dialog
        disableAutoFocus
        open={show}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="delete-messages-modal"
      >
        {!loading ? (
          <>
            {children}
            <div className="delete-messages-modal-buttons-container">
              <DialogActions className="delete-messages-stack-container">
                <Stack
                  direction="row"
                  spacing={4}
                  className="messages-modal-buttons-stack"
                >
                  <Button
                    onClick={() => cancel()}
                    className="messages-delete-cancel-button"
                  >
                    Cancelar
                  </Button>
                  <Button
                    onClick={() => handleShowLoader()}
                    className="message-delete-accept-button"
                  >
                    Aceptar
                  </Button>
                </Stack>
              </DialogActions>
            </div>
          </>
        ) : (
          <>
            <div className="delete-messages-modal-title-container">
              <DialogTitle
                id="alert-dialog-title"
                className="delete-messages-modal-title"
              >
                Procesando...
              </DialogTitle>
            </div>
            <div className="delete-messages-modal-content">
              <DialogContent>
                <div className="delete-messages-modal-loader-container">
                  <Loader />
                </div>
              </DialogContent>
            </div>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default MessageModal;
