import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import "./styles.scss";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
} from "@mui/material";
import CustomTextInput from "../../../../components/UI/CustomTextInput";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import ReCAPTCHA from "react-google-recaptcha";

const RegisterForm = ({ handleRegister }) => {
  const captcha = useRef(null);
  const { handleSubmit, control } = useForm();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [validUser, setValidUser] = useState(false);

  const handleShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDown = (event) => {
    event.preventDefault();
  };

  const goBack = () => {
    navigate(-1);
  };

  const OnSubmitForm = (data) => {
    handleRegister(data);
  };

  const handleReCaptchaChange = () => {
    if (captcha.current.getValue()) {
      setValidUser(true);
    }
  };

  return (
    <div className="register-form-main-container">
      <Box className="register-form-grid-container">
        <Grid container spacing={2} className="register-form-grid">
          {/* nombre */}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className="register-form-grid-item"
          >
            <CustomTextInput
              name="name"
              control={control}
              label={"Nombre"}
              type={"text"}
              placeholder={"nombre"}
              rules={{
                required: {
                  value: true,
                  message: "Campo obligatorio",
                },
              }}
            />
          </Grid>
          {/* apellido */}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className="register-form-grid-item"
          >
            <CustomTextInput
              name="lastname"
              control={control}
              label={"Apellido"}
              type={"text"}
              placeholder={"apellido"}
              rules={{
                required: {
                  value: true,
                  message: "Campo obligatorio",
                },
              }}
            />
          </Grid>
          {/* username */}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className="register-form-grid-item"
          >
            <CustomTextInput
              name="username"
              control={control}
              label={"Nombre de usuario"}
              type={"text"}
              placeholder={"nombre de usuario"}
              rules={{
                required: {
                  value: true,
                  message: "Campo obligatorio",
                },
              }}
            />
          </Grid>
          {/* email */}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className="register-form-grid-item"
          >
            <CustomTextInput
              name="email"
              control={control}
              label={"Email"}
              type={"email"}
              placeholder={"username@example.com"}
              rules={{
                required: {
                  value: true,
                  message: "Campo obligatorio",
                },
              }}
            />
          </Grid>
          {/* password */}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className="register-form-grid-item"
          >
            <CustomTextInput
              name="password"
              control={control}
              label={"Contraseña"}
              type={showPassword ? "text" : "password"}
              placeholder={"Contraseña"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle-password-visibility"
                    onClick={handleShowPassword}
                    onMouseDown={handleMouseDown}
                    edge="end"
                  >
                    {showPassword ? (
                      <VisibilityOffOutlinedIcon />
                    ) : (
                      <VisibilityOutlinedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              rules={{
                required: {
                  value: true,
                  message: "Campo obligatorio",
                },
                minLength: {
                  value: 8,
                  message: "La contraseña debe contener al menos 8 dígitos",
                },
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className="register-form-grid-item"
          >
            <ReCAPTCHA
              ref={captcha}
              sitekey={`${process.env.REACT_APP_SITE_KEY}`}
              onChange={handleReCaptchaChange}
            />
          </Grid>
        </Grid>
      </Box>
      <div className="register-form-buttons-container">
        <Stack
          spacing={{ xs: 2, md: 6 }}
          className="register-buttons-stack"
          direction={{
            xs: "column-reverse",
            sm: "column-reverse",
            md: "row",
          }}
        >
          <Button
            variant="outlined"
            className="register-form-buttons"
            onClick={goBack}
          >
            Volver
          </Button>
          <Button
            variant="outlined"
            className="register-form-buttons"
            onClick={handleSubmit(OnSubmitForm)}
            disabled={!validUser}
          >
            Crear cuenta
          </Button>
        </Stack>
      </div>
    </div>
  );
};

export default RegisterForm;
