import React from "react";
import "./styles.scss";
import { Button, Divider, Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useNavigate } from "react-router-dom";
import { useBagagge } from "../../../../hooks/useBagagge";
import logo from '../../../../assets/images/1.jpeg'

const ActivitiesGridCard = ({ activity, isInBagagge }) => {
  const navigate = useNavigate();
  const { addToBagagge, removeFromBagagge } = useBagagge();

  const toDetail = () => {
    navigate(`/activities/detail/${activity.id}`);
  };

  const addActivity = () => {
    addToBagagge(activity);
  };

  const removeActivity = () => {
    removeFromBagagge(activity);
  };

  return (
    <div className="activities-grid-card">
      <section className="activities-grid-card-image-container">
        {activity?.images && activity?.images?.length > 0 ? (
          <img
            src={`${activity?.images[0]?.url}`}
            alt="activity-img"
            className="activities-grid-card-image"
          />
        ) : (
          <img
            src={logo}
            alt="activity-img"
            className="activities-grid-card-image"
          />
        )}
      </section>
      <section className="activities-grid-card-title-container">
        <Typography
          variant="body1"
          className="activities-grid-card-title"
          sx={{ margin: 0, padding: 0 }}
        >
          {activity.name}
        </Typography>
      </section>
      <Divider variant="middle" color={"#746587"} sx={{ mt: 1 }} />
      <section className="activities-grid-card-buttons-container">
        <Button className="activity-card-btn" onClick={toDetail}>
          ver detalles
        </Button>
        <Button
          className="activity-card-btn"
          onClick={!isInBagagge ? addActivity : removeActivity}
          endIcon={!isInBagagge ? <AddCircleIcon /> : <RemoveCircleIcon />}
        >
          {!isInBagagge ? "agregar" : "quitar"}
        </Button>
      </section>
    </div>
  );
};

export default ActivitiesGridCard;
