import React, { useState } from "react";
import "./styles.scss";
import { Button, Dialog, IconButton, MobileStepper } from "@mui/material";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import CloseIcon from "@mui/icons-material/Close";

const AmplifiedImage = ({ images, open, onClose, activeImage }) => {
    const [active, setActive] = useState(activeImage || 0);

    const steps = images?.length;

    const handleNext = () => {
        setActive((prevState) => prevState + 1);
    };

    const handleBack = () => {
        setActive((prevState) => prevState - 1);
    };

    return (
        <div className="amplified-image">
            <Dialog
                open={open}
                onClose={onClose}
                className="amplified-image-modal"
                id="amplified-image-modal"
                sx={{ margin: "0px", padding: "0px", width: "100vw", minHeight: "100%" }}
            >
                <div className="amplified-image-inner-container">
                    <section className="amplified-image-button-container">
                        <IconButton aria-label="close-zoom" size="large" onClick={onClose}>
                            <CloseIcon sx={{ color: '#fafafa' }} />
                        </IconButton>
                    </section>
                    <section className="amplified-image-stepper-container">
                        <MobileStepper
                            className='stepper-controls-container'
                            variant="text"
                            steps={steps}
                            position="static"
                            activeStep={active}
                            nextButton={
                                <Button size="small" onClick={handleNext} disabled={active === steps - 1}>
                                    <KeyboardArrowRight />
                                </Button>
                            }
                            backButton={
                                <Button size="small" onClick={handleBack} disabled={active === 0}>
                                    <KeyboardArrowLeft />
                                </Button>
                            }
                        />
                        <img
                            className="amplified-image-img"
                            src={images[active]?.url}
                            alt="imagen de la actividad"
                        />
                    </section>
                </div>
            </Dialog>
        </div>
    );
};

export default AmplifiedImage;
