import React, { useEffect, useState } from "react";
import "./styles.scss";
import { Button, Grid } from "@mui/material";
import { getActivitiesGridPresenter } from "../../infrastructure/presentation/PresenterProvider";
import { useBagagge } from "../../../../hooks/useBagagge";
import { useStepper } from "../../../../hooks/useStepper";
import ActivitiesPager from "../../components/ActivitiesPager";
import ActivitiesGridCard from "../../components/ActivitiesGridCards";

const ITEMS_PER_PAGE = 6;

const ActivitiesGrid = () => {
  const [activities, setActivities] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { bagagge, isDisabled, handleDisabled, checkActivityInBagagge } = useBagagge();
  const { activeStep, handleBack, handleNext } = useStepper();

  const viewHandlers = {
    getActivitiesSuccess(data) {
      console.log("Activities: ", data?.statusText);
      setActivities(data?.data?.items);
      setTotalPages(data?.data?.totalPages);
    },
    getActivitiesError(err) {
      console.log("Activities error: ", err);
    },
  };

  const presenter = getActivitiesGridPresenter(viewHandlers);

  useEffect(() => {
    presenter.present();
    presenter.getActivities({ page: currentPage, limit: ITEMS_PER_PAGE });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    if (bagagge.length !== 0) {
      handleDisabled(false);
    }
    return () => handleDisabled(true);
  }, [bagagge, handleDisabled]);


  const handlePageChange = (newPage) => {
    window.scrollTo(0, 0)
    setCurrentPage(newPage)
  }

  return (
    <div className="activities-grid-screen">
      <section className="activities-grid-main-container">
        <Grid container rowGap={3} className='activities-grid'  >
          {
            activities.map((activity) => {
              const isInBagagge = checkActivityInBagagge(activity);
              return (
                <Grid item xs={12} sm={12} md={4} lg={4} className='activities-grid-item' key={activity.id}>
                  <ActivitiesGridCard activity={activity} isInBagagge={isInBagagge} />
                </Grid>
              )
            })
          }
        </Grid>
      </section>
      <section className="activities-grid-pager-container">
        <ActivitiesPager totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
      </section>
      <section className="activities-grid-stepper-handlers">
        <Button disabled={activeStep === 0} onClick={handleBack} sx={{ mr: '5px' }}>
          Anterior
        </Button>
        <Button onClick={handleNext} disabled={isDisabled} sx={{ ml: '5px' }}>
          Siguiente
        </Button>
      </section>
    </div>
  );
};

export default ActivitiesGrid;
