import React, { useEffect, useState } from "react";
import "./styles.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DropzoneComponent } from "react-dropzone-component";
import {
  Button,
  Card,
  CardActions,
  CardMedia,
  Divider,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import { getActivitiesUploadImagesPresenter } from "../../infrastructure/presentation/presenterProvider";
import useFeedback from "../../../../hooks/useFeedback";

const UploadImages = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { name } = state.activity;
  const [dropzone, setDropzone] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [activityImages, setActivityImages] = useState([]);
  const { setFeedback } = useFeedback()

  const djsConfig = {
    autoProcessQueue: false,
    addRemoveLinks: true,
    acceptedFiles: "image/jpeg,image/png,image/gif",
    dictDefaultMessage:
      "Arrastre y suelte una imagen para subir o haga click aqui!",
    paramName: "image",
    parallelUploads: 100,
    maxFiles: 100,
    dictRemoveFile: "Eliminar",
    headers: {
      "x-csrf-token": document
        .querySelector("meta[name=csrf-token]")
        .getAttributeNode("content").value,
    },
  };

  const componentConfig = {
    postUrl: `${process.env.REACT_APP_BASE_URL}/activities/upload/id/${id}`,
  };

  /* let dropzone; */

  const eventHandlers = {
    init: (dz) => setDropzone(dz),
    drop: (file) => {
      console.log("--------------- Dropped ---------------", file);
    },
    addedfile: (file) => {
      console.log("--------------- added ---------------", file);
    },
    success: (file) => {
      console.log("--------------- success ---------------", file);
    },
    removedfile: (file) => {
      console.log("--------------- remove ---------------", file);
    },
  };

  const handlePost = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dropzone.processQueue();
    setTimeout(() => {
      dropzone.removeAllFiles(true);
    }, 1000);
    setRefresh(true);
    setFeedback({
      open: true,
      error: false,
      message: 'Imagen añadida'
    })
  };

  const viewHandlers = {
    getImagesSuccess(data) {
      console.log("<<<<<<IMAGES>>>>>>>", data.statusText);
      setActivityImages(data.data.images);
    },
    getImagesError(err) {
      console.log("<<<<<<IMAGES ERROR>>>>>>>", err);
    },
    deleteImageSuccess(data) {
      console.log("<<<<<<DELETE>>>>>>>", data.statusText);
      setFeedback({
        open: true,
        error: false,
        message: 'Imagen eliminada'
      })
    },
    deleteImageError(err) {
      console.log("<<<<<<DELETE ERROR>>>>>>>", err);
      setFeedback({
        open: true,
        error: true,
        message: 'No se pudo eliminar la imagen'
      })
    },
  };

  const presenter = getActivitiesUploadImagesPresenter(viewHandlers);

  useEffect(() => {
    presenter.present();
    presenter.getActivityImages(id);
    return () =>
      setTimeout(() => {
        setRefresh(false);
      }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const deleteImage = (id) => {
    presenter.deleteActivityImage(id);
    setRefresh(true);
  };

  return (
    <div className="upload-images-screen">
      <div className="dropzone-main-container">
        <div className="dropzone-title-container">
          <Typography variant="h5" gutterBottom>
            {name}
          </Typography>
        </div>
        <div className="dropzone-section">
          <DropzoneComponent
            config={componentConfig}
            eventHandlers={eventHandlers}
            djsConfig={djsConfig}
          />
          <Stack
            direction="row"
            spacing={1}
            className="dropzone-buttons-container"
            justifyContent="space-evenly"
          >
            <Button
              className="dropzone-back-btn"
              startIcon={<ChevronLeftIcon />}
              onClick={() => navigate(-1)}
            >
              Volver
            </Button>
            <Button
              className="dropzone-back-btn"
              endIcon={<CheckCircleOutlineIcon />}
              id="submit-button"
              onClick={(e) => handlePost(e)}
            >
              Agregar
            </Button>
          </Stack>
        </div>
        <Divider variant="middle" className="dropzone-separator" />
        {activityImages && activityImages.length > 0 ? (
          <div className="activity-image-viewer">
            <Grid container spacing={2} className="image-viewer-grid">
              {activityImages.map((file, index) => (
                <Grid
                  key={index}
                  item
                  xs={6}
                  sm={6}
                  md={4}
                  lg={4}
                  className="image-viewer-item"
                >
                  <Card className="image-viewer-card">
                    <CardMedia
                      className="image-viewer-card-media"
                      component="img"
                      alt={"activity-image"}
                      image={`${file.url}`}
                    />
                    <Divider variant="middle" className="dropzone-separator" />
                    <CardActions className="image-viewer-card-actions">
                      <Tooltip title="Eliminar imagen">
                        <IconButton
                          aria-label="delete"
                          className="delete-image-button"
                          onClick={() => deleteImage(file.id_activity_image)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default UploadImages;
