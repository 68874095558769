import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import useUser from "../../hooks/useUser";

const PrivateRoute = ({ Component, roles }) => {
  const { user, verifySessionStatus, logout } = useUser();

  useEffect(() => {
    if (!verifySessionStatus) {
      logout();
      <Navigate to="/" />;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!user) {
    return <Navigate to={"/"} />;
  } else if (roles && roles.indexOf(user.role) === -1) {
    logout();
    return <Navigate to={{ pathname: "/" }} />;
  } else {
    return <Component />;
  }
};

export default PrivateRoute;
