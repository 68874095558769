import React, { useState } from "react";
import { useForm } from "react-hook-form";
import "./styles.scss";
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
} from "@mui/material";
import CustomTextInput from "../../../../components/UI/CustomTextInput";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

const ChangePasswordForm = ({ changePass }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
  } = useForm();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleShowPassword = () => setShowPassword((show) => !show);

  const handleShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDown = (event) => {
    event.preventDefault();
  };

  const handelMouseDownConfirm = (event) => {
    event.preventDefault();
  };

  const onSubmit = (data) => {
    changePass(data);
  };

  return (
    <div className="change-pass-form-main-container">
      <Box className="change-pass-form-grid-container">
        <Grid container spacing={2} className="change-pass-form-grid">
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className="change-pass-form-grid-item"
          >
            <CustomTextInput
              name="password"
              control={control}
              label={"Nueva contraseña"}
              type={showPassword ? "text" : "password"}
              placeholder={"Contraseña"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle-password-visibility"
                    onClick={handleShowPassword}
                    onMouseDown={handleMouseDown}
                    edge="end"
                  >
                    {showPassword ? (
                      <VisibilityOffOutlinedIcon />
                    ) : (
                      <VisibilityOutlinedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              rules={{
                required: {
                  value: true,
                  message: "Campo obligatorio",
                },
                minLength: {
                  value: 8,
                  message: "La contraseña debe contener al menos 8 dígitos",
                },
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className="change-pass-form-grid-item"
          >
            <CustomTextInput
              name="confirmPassword"
              control={control}
              label={"Confirmar contraseña"}
              type={showConfirmPassword ? "text" : "password"}
              placeholder={"Contraseña"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle-password-visibility"
                    onClick={handleShowConfirmPassword}
                    onMouseDown={handelMouseDownConfirm}
                    edge="end"
                  >
                    {showConfirmPassword ? (
                      <VisibilityOffOutlinedIcon />
                    ) : (
                      <VisibilityOutlinedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              rules={{
                required: {
                  value: true,
                  message: "Campo obligatorio",
                },
                minLength: {
                  value: 8,
                  message: "La contraseña debe contener al menos 8 dígitos",
                },
                validate: (value) => {
                  if (value === getValues("password")) {
                    return true;
                  } else {
                    return false;
                  }
                },
              }}
            />
            {errors.confirmPassword?.type === "validate" && (
              <FormHelperText error={true} className="change-pass-error-text">
                Las contraseñas ingresadas no coinciden
              </FormHelperText>
            )}
          </Grid>
        </Grid>
      </Box>
      <div className="change-pass-form-btns-container">
        <Stack
          spacing={{ xs: 4, md: 6 }}
          className="change-pass-form-buttons-stack"
          direction={{
            xs: "column-reverse",
            sm: "column-reverse",
            md: "row",
          }}
        >
          <Button
            variant="outlined"
            className="change-pass-form-button"
            onClick={handleSubmit(onSubmit)}
          >
            Confirmar
          </Button>
        </Stack>
      </div>
    </div>
  );
};

export default ChangePasswordForm;
