import React from "react";
import "./styles.scss";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Box, Button, Grid, Stack } from "@mui/material";
import CustomTextInput from "../../../../components/UI/CustomTextInput";

const VerifyEmailAddress = ({ verify }) => {
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm();

  const verifyEmail = (data) => {
    verify(data);
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="verify-email-mail-container">
      <Box className="verifyl-email-grid-container">
        <Grid container spacing={2} className="verify-email-grid">
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className="verify-email-grid-item"
          >
            <div className="verify-email-disc">
              <h5 className="verfy-email-disc-title">
                ¿Listo para cambiar tu contraseña?
              </h5>
              <p className="verify-email-disc-text">
                Por favor, proporciona la dirección de correo electrónico
                asociada a tu cuenta. Te enviaremos un correo electrónico con un
                enlace para cambiar tu contraseña.
              </p>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className="verify-email-grid-item"
          >
            <CustomTextInput
              name="email"
              control={control}
              label={"Email"}
              type={"email"}
              placeholder={"username@example.com"}
              rules={{
                required: {
                  value: true,
                  message: "Campo obligatorio",
                },
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <div className="verify-email-buttons-container">
        <Stack
          spacing={{ xs: 4, md: 6 }}
          className="verify-email-buttons-stack"
          direction={{
            xs: "column-reverse",
            sm: "column-reverse",
            md: "row",
          }}
        >
          <Button
            variant="outlined"
            className="verify-email-buttons"
            onClick={goBack}
          >
            volver
          </Button>
          <Button
            variant="outlined"
            className="verify-email-buttons"
            onClick={handleSubmit(verifyEmail)}
          >
            Verificar Email
          </Button>
        </Stack>
      </div>
    </div>
  );
};

export default VerifyEmailAddress;
