import React, { useEffect } from "react";
import "./styles.scss";
import LoginForm from "../../components/LoginForm";
import useUser from "../../../../hooks/useUser";
import { useNavigate } from "react-router-dom";
import { getLoginPresenter } from "../../infrastructure/presentation/presenterProvider";
import Swal from "sweetalert2/src/sweetalert2.js";
import logoAlert from "../../../../assets/images/test.png";

const Login = () => {
  const { login, isLoading, isLogged, hasLoginError } = useUser();
  const navigate = useNavigate();

  const viewHandlers = {
    isAdmin(data) {
      console.log("<<<<<<isAdmin>>>>>>>", data.data.message);
      Swal.fire({
        title: `Bienvenido`,
        confirmButtonText: "Ingresar",
        customClass: {
          title: "admin-pass-title",
          confirmButton: "admin-pass-button",
        },
      });
    },
    isNotAdmin(err) {
      console.log("<<<<<<isAdmin>>>>>>>", err);
      Swal.fire({
        title: `Contraseña incorrecta`,
        confirmButtonText: "Aceptar",
        customClass: {
          title: "admin-pass-title",
          confirmButton: "admin-pass-button",
        },
      });
      setTimeout(() => {
        Swal.close();
        navigate("/");
      }, 2000);
    },
    loginSuccess(data) {
      console.log("<<<<<<LOGIN>>>>>>>", data.data.message);
      login(data.data);
    },
    loginError(err) {
      console.log("<<<<<<LOGIN ERROR>>>>>>>", err.response.data.message);
      login(err);
    },
  };

  const presenter = getLoginPresenter(viewHandlers);

  const handleLogin = (data) => {
    presenter.login(data);
  };

  useEffect(() => {
    presenter.present();
    if (!isLogged) {
      Swal.fire({
        inputLabel: "Ingrese su contraseña",
        input: "password",
        confirmButtonText: "Confirmar",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        inputAttributes: {
          autocapitalize: "off",
          autocorrect: "off",
        },
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        customClass: {
          inputLabel: "admin-pass-title",
          image: "admin-pass-image",
          htmlContainer: "admin-pass-text",
          input: "admin-pass-input",
          confirmButton: "admin-pass-button",
          cancelButton: "admin-pass-button",
        },
        imageUrl: logoAlert,
        imageAlt: "logo-mn",
        preConfirm: (value) => {
          presenter.isAdmin(value);
        },
      }).then((result) => {
        if (result.dismiss === Swal.DismissReason.cancel) {
          navigate(-1);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLogged) navigate("/");
  }, [isLogged, navigate]);

  return (
    <div className="login-screen-main-container">
      <div className="login-screen-title-container">
        <h2 className="login-screen-title">Login</h2>
      </div>
      <div className="login-screen-form-container">
        <LoginForm
          handleLogin={handleLogin}
          loading={isLoading}
          hasLoginError={hasLoginError}
        />
      </div>
    </div>
  );
};

export default Login;
