import React, { createContext, useState } from "react";

const LocationContext = createContext();

const LocationContextProvider = ({ children }) => {
  const [allCountries, setAllCountries] = useState([]);
  const [country, setCountry] = useState({});
  const [state, setState] = useState({});
  const [city, setCity] = useState({});

  const data = {
    allCountries,
    setAllCountries,
    country,
    setCountry,
    state,
    setState,
    city,
    setCity,
  };

  return (
    <LocationContext.Provider value={data}>{children}</LocationContext.Provider>
  );
};

export { LocationContextProvider };

export default LocationContext;
