import React from "react";
import "./styles.scss";
import { GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const CustomToolbar = () => {
  const navigate = useNavigate();

  const toCreate = () => {
    navigate("/activities/new");
  };

  return (
    <div className="custom-toolbar-main-container">
      <GridToolbarContainer className="custom-toolbar-grid-container">
        <div className="custom-toolbar-button-container">
          <Button
            onClick={toCreate}
            className="custom-toolbar-button"
            startIcon={<AddCircleOutlineIcon />}
          >
            Nueva actividad
          </Button>
        </div>
        <div className="custom-toolbar-search-bar-container">
          <GridToolbarQuickFilter className="search-bar" />
        </div>
      </GridToolbarContainer>
    </div>
  );
};

export default CustomToolbar;
