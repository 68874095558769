import axios from "axios";

export const HttpGetActivityImagesGateway = () => {
  return {
    getImages: async (id) => {
      return axios.get(
        `${process.env.REACT_APP_BASE_URL}/activities/upload/get/id/${id}`
      );
    },
  };
};
