import axios from "axios";

export const HttpDeleteActivityGateway = () => {
  return {
    deleteActivity: async (id) => {
      return axios.delete(
        `${process.env.REACT_APP_BASE_URL}/activities/delete/id/${id}`
      );
    },
  };
};
