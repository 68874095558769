import React, { useEffect, useMemo, useState } from "react";
import { Controller } from "react-hook-form";
import "./styles.scss";
import {
  Avatar,
  Box,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const searchCriteria = (text, searchText) => {
  return text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
};

const CustomSelect = ({
  name,
  control,
  label,
  options,
  setOption,
  rules,
  clear,
  value,
}) => {
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);

  const optionsToShow = useMemo(
    () =>
      options !== undefined &&
      options.filter((option) => searchCriteria(`${option.name}`, searchText)),
    [options, searchText]
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setSearchText("");
    setOpen(false);
  };

  const handleChange = (option, error) => {
    if (error) {
      clear(name);
    }
    if (option.flag) {
      setSelectedOption(`${option.flag} ${option.name}`);
      setOpen(false);
      setOption(option);
    } else {
      setSelectedOption(option.name);
      setOpen(false);
      setOption(option);
    }
  };

  useEffect(() => {
    setSelectedOption(null);
  }, [options]);

  const generateOptions = () => {
    return optionsToShow.map((option, index) => {
      return (
        <MenuItem key={index} value={option}>
          <ListItemAvatar>
            <Avatar>
              <>{option.flag ? option.flag : <LocationOnIcon />}</>
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={option.name} />
        </MenuItem>
      );
    });
  };

  return (
    <Box className="custom-select">
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={null}
        render={({ fieldState: { error } }) => (
          <FormControl
            fullWidth
            className="custom-select-form-control"
            error={error ? true : false}
          >
            <InputLabel
              id="custom-select-label-id"
              className={error ? "" : "custom-select-label"}
              sx={{ fontWeight: 500 }}
            >
              {label}
            </InputLabel>
            <Select
              className="custom-select-select"
              MenuProps={{ autoFocus: false }}
              labelId="custom-select-label-id"
              open={open}
              id={"custom-select-id"}
              value={selectedOption || value}
              label={label}
              onChange={(e) => handleChange(e.target.value, error)}
              onOpen={handleOpen}
              onClose={handleClose}
              renderValue={() => selectedOption || value.label}
            >
              <ListSubheader>
                <TextField
                  size="small"
                  autoFocus
                  placeholder="Escriba para buscar..."
                  className="custom-select-input"
                  fullWidth
                  inputProps={{
                    startadornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => setSearchText(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key !== "Escape") {
                      e.stopPropagation();
                    }
                  }}
                />
              </ListSubheader>
              {generateOptions()}
            </Select>
            {error && error && (
              <FormHelperText className={"custom-select-error"}>
                {error ? error.message : null}
              </FormHelperText>
            )}
          </FormControl>
        )}
      />
    </Box>
  );
};

export default CustomSelect;
