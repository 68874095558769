import { HttpGetActivitiesGateway } from "../gateways/HttpGetActivitiesGateway";
import { HttpCreatePackageGateway } from "../gateways/HttpCreatePackageGateway";

import { ActivitiesGridPresenter } from "./ActivitiesGridPresenter";
import { CheckoutPresenter } from "./CheckoutPresenter";

export const getActivitiesGridPresenter = (viewHandlers) => {
  const getActivitiesGateway = HttpGetActivitiesGateway();
  return ActivitiesGridPresenter(getActivitiesGateway, viewHandlers);
};

export const getCheckoutPresenter = (viewHandlers) => {
  const getCreatePackageGateway = HttpCreatePackageGateway();
  return CheckoutPresenter(viewHandlers, getCreatePackageGateway);
};
