export const MessagesUpdatePresenter = (
  updateMessageGateway,
  getMessageGateway,
  viewHandlers
) => {
  return {
    present() {
      console.log("Messages detail was rendered!");
    },
    updateMessage(data) {
      const updateMessagePromise = updateMessageGateway.update(data);

      updateMessagePromise
        .then((data) => {
          viewHandlers.updateMessageSuccess(data);
        })
        .catch((err) => {
          viewHandlers.updateMessageError(err);
        });
    },
    getMessage(id) {
      const getMessagePromise = getMessageGateway.getOneMessage(id);

      getMessagePromise
        .then((data) => {
          viewHandlers.getMessageSuccess(data);
        })
        .catch((err) => {
          viewHandlers.getMessageError(err);
        });
    },
  };
};
