import React from "react";
import "./styles.scss";
import {
  Avatar,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";

const CheckoutContactGrid = ({ data }) => {
  return (
    <div className="contact-grid-main-container">
      <Grid container className="contact-grid">
        <Grid item xs={12} sm={12} md={6} lg={6} className="contact-grid-item">
          <ListItem className="contact-data-item">
            <ListItemAvatar>
              <Avatar>
                <ContactPageIcon className="contact-data-icon" />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Nombre: " secondary={data.name} />
          </ListItem>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className="contact-grid-item">
          <ListItem className="contact-data-item">
            <ListItemAvatar>
              <Avatar>
                <ContactPageIcon className="contact-data-icon" />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Apellido: " secondary={data.lastname} />
          </ListItem>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className="contact-grid-item">
          <ListItem className="contact-data-item">
            <ListItemAvatar>
              <Avatar>
                <AlternateEmailIcon className="contact-data-icon" />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Email: " secondary={data.email} />
          </ListItem>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className="contact-grid-item">
          <ListItem className="contact-data-item">
            <ListItemAvatar>
              <Avatar>
                <PhoneIcon className="contact-data-icon" />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Teléfono: " secondary={data.phone} />
          </ListItem>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className="contact-grid-item"
        >
          <ListItem className="contact-data-item">
            <ListItemAvatar>
              <Avatar>
                <LocationOnIcon className="contact-data-icon" />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Ubicación: "
              secondary={
                data.state === null
                  ? `${data.country}`
                  : data.state !== null && data.city === null
                  ? `${data.state}, ${data.country}`
                  : `${data.city} - ${data.state}, ${data.country}`
              }
            />
          </ListItem>
        </Grid>
      </Grid>
    </div>
  );
};

export default CheckoutContactGrid;
