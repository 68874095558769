export const AdminPackagesPresenter = (
  viewHandlers,
  getPackagesGateway,
  deletePackageGateway
) => {
  return {
    present() {
      console.log("AdminPackages was rendered!");
    },
    getPackages() {
      const getPackagesPromise = getPackagesGateway.getAllPackages();

      getPackagesPromise
        .then((data) => {
          viewHandlers.getPackagesSuccess(data);
        })
        .catch((err) => {
          viewHandlers.getPackagesError(err);
        });
    },

    deletePackage(id) {
      const deletePackagePromise = deletePackageGateway.deletePackage(id);

      deletePackagePromise
        .then((data) => {
          viewHandlers.deletePackageSuccess(data);
        })
        .catch((err) => {
          viewHandlers.deletePackageError(err);
        });
    },
  };
};
