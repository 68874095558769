export const ActivitiesGridPresenter = (getActivitiesGateway, viewHandlers) => {
  return {
    present() {
      console.log("Activities grid was rendered!");
    },
    getActivities(data) {
      const getActivitiesPromise = getActivitiesGateway.getAllActivities(data);

      getActivitiesPromise
        .then((data) => {
          viewHandlers.getActivitiesSuccess(data);
        })
        .catch((err) => {
          viewHandlers.getActivitiesError(err);
        });
    },
  };
};
