import React, { useEffect } from "react";
import "./styles.scss";
import { useForm } from "react-hook-form";
import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import CustomDatePicker from "../../../../components/UI/CustomDatePicker";
import CustomTextInput from "../../../../components/UI/CustomTextInput";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

const PackageForm = ({ confirmStay, stay, goBack }) => {
  const { handleSubmit, control, clearErrors, getValues, setValue, reset } =
    useForm();
  dayjs.extend(isSameOrAfter);

  const onSubmitData = (data) => {
    confirmStay(data);
    reset({});
  };

  const correctDate = (date) => {
    const auxDate = new Date(date).toLocaleDateString().split("/");
    return dayjs()
      .set("date", auxDate[0])
      .set("month", auxDate[1] - 1)
      .set("year", auxDate[2]);
  };

  useEffect(() => {
    if (Object.keys(stay).length !== 0) {
      setValue("from_date", correctDate(stay.from_date));
      setValue("to_date", correctDate(stay.to_date));
      setValue("people", stay.people);
      setValue("consultation", stay.consultation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const verifyToDate = (value) => {
    return value >= getValues("from_date");
  };

  const todayOrAfter = (value) => {
    const today = dayjs();
    return value.isSameOrAfter(dayjs(today, "DD/MM/YYYY"), "day", "month");
  };

  return (
    <div className="package-form-main-container">
      <div className="date-selector-main-container">
        <Typography variant="h6" className="date-selector-title">
          Seleccione el rango de fechas de su estadía
        </Typography>
        <Box className="date-selector-grid-container">
          <Grid container spacing={2} className="date-selector-grid">
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              className="date-selector-grid-item"
            >
              <CustomDatePicker
                name="from_date"
                disabled={false}
                control={control}
                label="Desde"
                value={undefined}
                defaultValue={null}
                clearErrors={clearErrors}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                  validate: {
                    minDate: (value) =>
                      todayOrAfter(value) || "Fecha seleccionada no válida",
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              className="date-selector-grid-item"
            >
              <CustomDatePicker
                name="to_date"
                disabled={false}
                control={control}
                label="Hasta"
                value={undefined}
                defaultValue={null}
                clearErrors={clearErrors}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                  validate: {
                    minDate: (value) =>
                      todayOrAfter(value) || "Fecha seleccionada no válida",
                    verifyDate: (value) =>
                      verifyToDate(value) || "Rango seleccionado no válido",
                  },
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </div>
      <Divider variant="middle" className="separator" color="#704863" />
      <Box className="package-form-grid-container">
        <Grid container className="package-form-main-grid">
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className="package-form-main-grid-item"
          >
            <CustomTextInput
              name="people"
              control={control}
              label="Cantidad de personas"
              type={"number"}
              rules={{
                required: {
                  value: true,
                  message: "Campo obligatorio",
                },
                min: {
                  value: 1,
                  message: "El número ingresado no es válido",
                },
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className="package-form-main-grid-item"
          >
            <CustomTextInput
              name="consultation"
              control={control}
              label={"Consulta"}
              type={"text"}
              multiline={true}
              rows={5}
              placeholder={"Su consulta..."}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className="package-form-main-grid-btn-container"
          >
            <Stack
              direction={{
                xs: "column-reverse",
                sm: "column-reverse",
                md: "row",
              }}
              spacing={{
                xs: 3,
                sm: 3,
                md: 6,
                lg: 6,
              }}
            >
              <Button onClick={goBack}>Anterior</Button>
              <Button onClick={handleSubmit(onSubmitData)}>Siguiente</Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default PackageForm;
