import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import "./styles.scss";
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
} from "@mui/material";
import CustomTextInput from "../../../../components/UI/CustomTextInput";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

const LoginForm = ({ handleLogin, loading, hasLoginError }) => {
  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDown = (event) => {
    event.preventDefault();
  };

  const OnSubmitForm = (data) => {
    handleLogin(data);
  };

  const toRegister = () => {
    navigate("/signin");
  };

  const recoveryPassword = () => {
    navigate("/resetpassword");
  };

  useEffect(() => {
    if (hasLoginError) {
      setError("email", {
        types: {
          invalid: "Email ingresado no válido",
        },
      });
      setError("password", {
        types: {
          invalid: "Contraseña ingresada no válida",
        },
      });
    }
  }, [hasLoginError, setError]);

  return (
    <div className="login-form-main-container">
      <Box className="login-form-grid-container">
        <Grid container spacing={2} className="login-form-grid">
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className="login-form-grid-item"
          >
            <CustomTextInput
              name="email"
              control={control}
              label={"Email"}
              type={"email"}
              placeholder={"username@example.com"}
              rules={{
                required: {
                  value: true,
                  message: "Campo obligatorio",
                },
              }}
            />
            {hasLoginError && errors.email && (
              <FormHelperText error={true} className="error-helper-text">
                {errors.email.types.invalid}
              </FormHelperText>
            )}
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className="login-form-grid-item"
          >
            <CustomTextInput
              name="password"
              control={control}
              label={"Contraseña"}
              type={showPassword ? "text" : "password"}
              placeholder={"Contraseña"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle-password-visibility"
                    onClick={handleShowPassword}
                    onMouseDown={handleMouseDown}
                    edge="end"
                  >
                    {showPassword ? (
                      <VisibilityOffOutlinedIcon />
                    ) : (
                      <VisibilityOutlinedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              rules={{
                required: {
                  value: true,
                  message: "Campo obligatorio",
                },
              }}
            />
            {hasLoginError && errors.password && (
              <FormHelperText error={true} className="error-helper-text">
                {errors.password.types.invalid}
              </FormHelperText>
            )}
          </Grid>
        </Grid>
      </Box>
      <div className="login-form-buttons-container">
        <Stack
          spacing={{ xs: 2, md: 6 }}
          className="login-buttons-stack"
          direction={{
            xs: "column-reverse",
            sm: "column-reverse",
            md: "row",
          }}
        >
          <Button
            variant="outlined"
            className="login-buttons"
            onClick={toRegister}
          >
            Registro
          </Button>
          <Button
            variant="outlined"
            className="login-buttons"
            onClick={handleSubmit(OnSubmitForm)}
          >
            {loading ? "Cargando..." : "Iniciar sesión"}
          </Button>
        </Stack>
        <div className="forgotten-password-container">
          <Button
            className="forgotten-password-button"
            onClick={recoveryPassword}
          >
            <h6>Olvidé mi contraseña</h6>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
