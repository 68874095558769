import React, { useState } from "react";
import "./styles.scss";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import logo from "../../../../assets/images/2-png.png";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useNavigate } from "react-router-dom";
import useUser from "../../../../hooks/useUser";
import CustomDrawer from "../Drawer";
import { Badge, Fab, Fade } from "@mui/material";
import { Navs } from "../../services/nav.services";
import Footer from "../../../../components/UI/footer";
import { useBagagge } from "../../../../hooks/useBagagge";
import Backpack from "../../../Backpack/views";
import useFeedback from "../../../../hooks/useFeedback";
import StatusBar from "../../../../components/UI/StatusBar";

const drawerWidth = 240;

const ElevationScroll = (props) => {
  const { children } = props;
  const trigger = useScrollTrigger();
  /* return (
    HideOnScroll
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  ); */
  return React.cloneElement(children, {
    elevation: trigger ? 8 : 0,
  });
};

const ScrollTop = (props) => {
  const { children } = props;
  const trigger = useScrollTrigger();

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );
    if (anchor) {
      anchor.scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box onClick={handleClick} role="presentation" className="scroll-button">
        {children}
      </Box>
    </Fade>
  );
};

function Navbar(props) {
  const { window, children } = props;
  const { isLogged, logout, user } = useUser();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { bagagge } = useBagagge();
  const { feedback, handleFeedbackClose } = useFeedback();

  const handleDrawerOpen = () => {
    setOpen((prevState) => !prevState);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const navItems = user
    ? Navs.find((item) => item.role === user.role)
    : Navs.find((item) => item.role === "visitor");

  return (
    <div className="navbar-main-container" id="back-to-top-anchor">
      <Box sx={{ display: "flex" }} className="navbar-main-box">
        {
          feedback.open && (
            <StatusBar
              feedback={feedback}
              handleClose={handleFeedbackClose}
            />
          )
        }
        <CssBaseline />
        <ElevationScroll {...props}>
          <AppBar component="nav" className="navbar-appbar">
            <Toolbar className="navbar-appbar-tool">
              <Button
                className="navbar-appbar-tool-first-button"
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerOpen}
                startIcon={<MenuIcon />}
                sx={{ mr: 2, display: { md: "none" } }}
              >
                Menu
              </Button>
              <div className="navbar-logo-container ">
                <button
                  className="navbar-logo-button"
                  onClick={() => navigate("/")}
                >
                  <img src={logo} alt="" className="navbar-monmar-logo" />
                </button>
              </div>
              <div
                className="navbar-backpack-container"
                display={bagagge.length <= 0 ? "none" : undefined}
              >
                <Box
                  sx={{ display: { md: 'none' } }}
                  className="navbar-nav-items-container"
                >
                  {navItems.routes.map((item, index) => (
                    <Button
                      key={index}
                      sx={{ color: "#fff" }}
                      className="navbar-nav-item"
                      onClick={() => navigate(`${item.url}`)}
                    >
                      {item.title}
                    </Button>
                  ))}
                  {isLogged && (
                    <Button className="navbar-nav-item" onClick={logout}>
                      Logout
                    </Button>
                  )}
                </Box>
                {bagagge && bagagge.length !== 0 && (
                  <div className="navbar-nav-item">
                    <Badge
                      badgeContent={bagagge.length}
                      color="error"
                      overlap="circular"
                    >
                      <Backpack />
                    </Badge>
                  </div>
                )}
              </div>
            </Toolbar>
          </AppBar>
        </ElevationScroll>
        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={open}
            onClose={handleDrawerOpen}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { md: 'none' },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {<CustomDrawer setOpen={setOpen} />}
          </Drawer>
        </Box>
        <div className="drawer-main-content-container">
          <Box
            component="main"
            sx={{ flexGrow: 1 }}
            className="main-box-container"
          >
            <div className="drawer-children-container">{children}</div>
          </Box>
          <Footer />
        </div>
      </Box>
      <ScrollTop {...props}>
        <Fab size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </div>
  );
}

export default Navbar;
