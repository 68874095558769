import { HttpGetPackagesGateway } from "../gateways/HttpGetPackagesGateway";
import { HttpGetPackageGateway } from "../gateways/HttpGetPacakgeGateway";
import { HttpDeletePackageGateway } from "../gateways/HttpDeletePackageGateway";

import { AdminPackagesPresenter } from "./AdminPackagesPresenter";
import { PackageDetailPresenter } from "./PackageDetailPresenter";

export const getAdminPackagesPresenter = (viewHandlers) => {
  const getPackagesGateway = HttpGetPackagesGateway();
  const deletePackageGateway = HttpDeletePackageGateway();
  return AdminPackagesPresenter(
    viewHandlers,
    getPackagesGateway,
    deletePackageGateway
  );
};

export const getPackageDetailPresenter = (viewHandlers) => {
  const getPackageGateway = HttpGetPackageGateway();
  return PackageDetailPresenter(viewHandlers, getPackageGateway);
};
