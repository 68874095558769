import React, { useEffect } from "react";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import ContactForm from "../../components/ContactForm";
import { Typography } from "@mui/material";
import { getMessageCreatePresenter } from "../../infrastructure/presentation/presenterProvider";
import useFeedback from "../../../../hooks/useFeedback";

const MessagesCreate = () => {
  const navigate = useNavigate();
  const { setFeedback } = useFeedback()

  const viewHandlers = {
    createMessageSuccess(data) {
      console.log("<<<<<<MESSAGE>>>>>>>", data.statusText);
      setFeedback({
        open: true,
        error: false,
        message: 'Su mensaje fue enviado con éxito'
      })
    },
    createMessageError(err) {
      console.log("<<<<<<MESSAGE ERROR>>>>>>>", err);
      setFeedback({
        open: true,
        error: true,
        message: 'Su mensaje no se pudo enviar'
      })
    },
  };

  const presenter = getMessageCreatePresenter(viewHandlers);

  useEffect(() => {
    presenter.present();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreateMessage = (data) => {
    presenter.createMessage(data);
    navigate("/");
  };

  return (
    <div className="contact-screen">
      <div className="contact-screen-main-container">
        <div className="contact-screen-title-container">
          <Typography
            variant="h4"
            gutterBottom
            className="contact-screen-title"
          >
            Dejanos tu consulta
          </Typography>
        </div>
        <div className="contact-screen-form-container">
          <ContactForm createNewMessage={handleCreateMessage} />
        </div>
      </div>
    </div>
  );
};

export default MessagesCreate;
