import React, { useEffect } from "react";
import "./styles.scss";
import { useNavigate, useParams } from "react-router-dom";
import ChangePasswordForm from "../../components/ChangePassForm";
import { getChangePasswordPresenter } from "../../infrastructure/presentation/presenterProvider";
import useFeedback from "../../../../hooks/useFeedback";

const ChangePassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const { setFeedback } = useFeedback();

  const viewHandlers = {
    changePassSuccess(data) {
      console.log("<<<<<<CHANGE PASS>>>>>>>", data.data.message);
      setFeedback({
        open: true,
        error: false,
        message: 'Contraseña cambiada con éxito'
      })
      navigate('/login')
    },
    changePassError(err) {
      console.log("<<<<<<CHANGE PASS ERROR>>>>>>>", err.response.data.message);
      setFeedback({
        open: true,
        error: true,
        message: 'No se pudo cambiar la contraseña'
      })
    },
  };

  const presenter = getChangePasswordPresenter(viewHandlers);

  useEffect(() => {
    if (token.length < 5) {
      navigate("/");
    } else {
      presenter.present();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changePass = (data) => {
    presenter.changePassword({ ...data, token: token });
  };

  return (
    <div className="change-pass-screen-container">
      <div className="change-pass-title-container">
        <h2 className="change-pass-title">Cambiar contraseña</h2>
      </div>
      <div className="change-pass-form-container">
        <ChangePasswordForm changePass={changePass} />
      </div>
    </div>
  );
};

export default ChangePassword;
