export const ChangePasswordPresenter = (
  getChangePasswordGateway,
  viewHandlers
) => {
  return {
    present() {
      console.log("Change password screen was rendered!");
    },
    changePassword(data) {
      const changePassPromise = getChangePasswordGateway.changePass(data);

      changePassPromise
        .then((data) => {
          viewHandlers.changePassSuccess(data);
        })
        .catch((err) => {
          viewHandlers.changePassError(err);
        });
    },
  };
};
