import React, { useEffect, useState } from "react";
import "./styles.scss";
import {
  Box,
  Button,
  Divider,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useBagagge } from "../../../../hooks/useBagagge";
import { useStepper } from "../../../../hooks/useStepper";
import TabPanel from "../../../../components/UI/TabPanel";
import CheckoutList from "../../../../components/UI/CheckoutList";
import CheckoutStayGrid from "../../../../components/UI/CheckoutStayGrid";
import CheckoutContactGrid from "../../../../components/UI/CheckoutContactGrid";
import { getCheckoutPresenter } from "../../infrastructure/presentation/PresenterProvider";
import Swal from "sweetalert2/src/sweetalert2.js";
import { useNavigate } from "react-router-dom";

const allyProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const Checkout = () => {
  const [value, setValue] = useState(0);
  const { bagagge, stay, client, resetBagagge } = useBagagge();
  const { reset } = useStepper();
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleReset = () => {
    reset();
  };

  const handleSubmit = () => {
    const data = {
      bagagge,
      client,
      stay,
    };
    presenter.createPackage(data);
  };

  const viewHandlers = {
    createPackageSuccess(data) {
      console.log("<<<<<<Package>>>>>>>", data.statusText);
      Swal.fire({
        icon: "success",
        title: "Paquete creado con éxito",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        background: "#fff",
        customClass: {
          title: "create-package-title",
          confirmButton: "create-package-button",
        },
        timer: 2000,
      }).then((result) => {
        if (result.dismiss === Swal.DismissReason.timer) {
          resetBagagge();
          reset();
          navigate("/");
        }
      });
    },
    createPackageError(err) {
      console.log("<<<<<<Package Error>>>>>>>", err);
      Swal.fire({
        icon: "error",
        title: "No se ha podido crear el paquete",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        background: "#fff",
        customClass: {
          title: "create-package-title",
          confirmButton: "create-package-button",
        },
        timer: 2000,
      }).then((result) => {
        if (result.dismiss === Swal.DismissReason.timer) {
          navigate("/");
        }
      });
    },
  };

  const presenter = getCheckoutPresenter(viewHandlers);

  useEffect(() => {
    presenter.present();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="checkout-screen">
      <div className="checkout-screen-main-container">
        <div className="checkout-screen-title-container">
          <Typography variant="h5" gutterBottom className="checkout-data-title">
            Confirmar
          </Typography>
        </div>
        <Divider variant="middle" className="checkout-separator" />
        <Box className="checkout-screen-tabs-container">
          <Box className="checkout-tabs-buttons">
            <Tabs
              className="checkout-tabs-button-nav"
              value={value}
              onChange={handleChange}
              aria-label="tabs"
              variant="fullWidth"
              textColor="secondary"
              indicatorColor="none"
              sx={{
                "& button": { backgroundColor: "#eee" },
                "& button.Mui-selected": {
                  backgroundColor: "#E4DBEE",
                },
              }}
            >
              <Tab
                className="checkout-tab"
                label="Actividades seleccionadas"
                {...allyProps(0)}
                wrapped
              />
              <Tab
                className="checkout-tab"
                label="Información de estadía"
                {...allyProps(1)}
                wrapped
              />
              <Tab
                className="checkout-tab"
                label="Información de contacto"
                {...allyProps(2)}
                wrapped
              />
            </Tabs>
          </Box>
          <div className="tabpanel-container">
            <TabPanel value={value} index={0} className="checkout-tabpanel">
              <div className="tabpanel-children-container">
                <CheckoutList data={bagagge} />
              </div>
            </TabPanel>
            <TabPanel value={value} index={1} className="checkout-tabpanel">
              <CheckoutStayGrid data={stay} />
            </TabPanel>
            <TabPanel value={value} index={2} className="checkout-tabpanel">
              <CheckoutContactGrid data={client} />
            </TabPanel>
            <div className="checkout-bttons-container">
              <Stack
                direction={{
                  xs: "column-reverse",
                  sm: "column-reverse",
                  md: "row",
                }}
                spacing={{
                  xs: 3,
                  sm: 3,
                  md: 6,
                  lg: 6,
                }}
              >
                <Button onClick={handleReset}>Reiniciar</Button>
                <Button onClick={handleSubmit}>Enviar</Button>
              </Stack>
            </div>
          </div>
        </Box>
      </div>
    </div>
  );
};

export default Checkout;
