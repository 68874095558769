import axios from "axios";

export const HttpRegisterGateway = () => {
  return {
    register: async (data) => {
      const { name, lastname, username, email, password } = data;
      const body = {
        name,
        lastname,
        username,
        email,
        password,
      };
      return axios.post(
        `${process.env.REACT_APP_BASE_URL}/onboarding/register`,
        body
      );
    },
  };
};
