export const ActivitiesCreatePresenter = (
  createActivityGateway,
  viewHandlers
) => {
  return {
    present() {
      console.log("Activities create was rendered!");
    },

    createActivity(data) {
      const createActivityPromise = createActivityGateway.createActivity(data);

      createActivityPromise
        .then((data) => {
          viewHandlers.createActivitySuccess(data);
        })
        .catch((err) => {
          viewHandlers.createActivityError(err);
        });
    },
  };
};
