import React, { useEffect, useState } from "react";
import "./styles.scss";
import logo from "../../../assets/images/test.png";
import Carousel from "../components/Carousel";
import { getLandingPresenter } from "../infrastructure/presentation/PresenterProvider";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const LandingPage = () => {
  const [activities, setActivities] = useState([]);
  const navigate = useNavigate();

  const viewHandlers = {
    getActivitiesSuccess(data) {
      console.log("<<<<<<ACTIVITIES>>>>>>>", data.statusText);
      setActivities(data?.data?.data?.filter((item) => item?.availability === true));
    },
    getActivitiesError(err) {
      console.log("<<<<<<ACTIVITIES ERROR>>>>>>>", err);
    },
  };

  const presenter = getLandingPresenter(viewHandlers);

  useEffect(() => {
    presenter.present();
    presenter.getAllActivities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="landing-main-container">
      <header className="landing-header">
        <div className="landing-header-content">
          <div className="landing-header-title-container">
            <img src={logo} alt="MONMAR_LOGO" className="landing-header-logo" />
          </div>
          <div className="landing-header-subtitle">
            En MONMAR consideramos que es indispensable conocer los gustos y
            deseos del turista, para diseñar el viaje soñado
          </div>
        </div>
      </header>
      <section className="landing-section">
        <div className="landing-section-picture-container">
          <div className="landing-section-picture-circular-container"></div>
        </div>
        <div className="landing-section-content-container">
          <div className="landing-section-content-title">¿Quiénes somos?</div>
          <div className="landing-section-content-data">
            MONMAR nace en Mendoza, de dos colegas, amigos y socios, que nos
            formamos y trabajamos en el corazón del turismo local y un día
            decidimos ser productores turísticos, sabiendo que todos los
            destinos tienen riquezas para conocer y desarrollar esta industria,
            la cual amamos y valoramos.
          </div>
        </div>
      </section>
      <section className="landing-products-section">
        <div className="products-section-title-container">
          <h4 className="products-section-title">Nuestros servicios</h4>
          <p className="products-section-desc">Seleccioná tus actividades y armá tu paquete</p>
        </div>
        <div className="products-carousel-container">
          {activities && <Carousel activities={activities} />}
        </div>
        <div className="landing-button-container">
          <Button className='landing-button' onClick={() => navigate('/packages')}>
            Ver todas las actividades
          </Button>
        </div>
      </section>
    </div>
  );
};

export default LandingPage;
