export const RegisterPresenter = (
  getRegisterGateway,
  getIsAdminGateway,
  viewHandlers
) => {
  return {
    present() {
      console.log("Register screen was rendered!");
    },
    register(data) {
      const registerPromise = getRegisterGateway.register(data);

      registerPromise
        .then((data) => {
          viewHandlers.registerSuccess(data);
        })
        .catch((err) => {
          viewHandlers.registerError(err);
        });
    },
    isAdmin(data) {
      const isAdminPromise = getIsAdminGateway.verify(data);

      isAdminPromise
        .then((data) => {
          viewHandlers.isAdmin(data);
        })
        .catch((err) => {
          viewHandlers.isNotAdmin(err);
        });
    },
  };
};
