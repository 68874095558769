export const ActivitiesUpdatePresenter = (
  viewHandlers,
  updateActivityGateway
) => {
  return {
    present() {
      console.log("Activities update was rendered!");
    },
    updateActivity(data) {
      const updateActivityPromise = updateActivityGateway.update(data);

      updateActivityPromise
        .then((data) => {
          viewHandlers.updateActivitySuccess(data);
        })
        .catch((err) => {
          viewHandlers.updateActivityError(err);
        });
    },
  };
};
