import { useContext } from "react";
import BagaggeContext from "../../context/bagaggeContext";

export const useBagagge = () => {
  const bagagge = useContext(BagaggeContext);

  if (bagagge === undefined) {
    throw new Error("useBagagge must be used withing a BagaggeProvider");
  }

  return bagagge;
};
