import { Pagination, Stack } from '@mui/material'
import React from 'react'

const ActivitiesPager = ({ totalPages, currentPage, onPageChange }) => {

  const handlePageChange = (evt, newPage) => {
    onPageChange(newPage)
  }

  return (
    <section className='activities-pager-main-container'>
      <Stack spacing={3} direction={'row'} justifyContent={'center'} alignItems={'center'} className='activities-pager-stack'>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          color='primary'
          sx={{ position: 'relative', zIndex: 0 }}
        />
      </Stack>
    </section>
  )
}

export default ActivitiesPager