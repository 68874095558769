import React, { useEffect, useState, useRef } from "react";
import "./styles.scss";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { Box, Button, Grid, Stack } from "@mui/material";
import CustomTextInput from "../../../../components/UI/CustomTextInput";
import { Country, State, City } from "country-state-city";
import CustomSelect from "../../../../components/UI/CustomSelect";
import { useGeolocation } from "../../../../hooks/useGeolocation";

const ClientForm = ({ submitClient, goBack, client }) => {
  const { handleSubmit, control, clearErrors, reset, setValue } = useForm();
  const {
    allCountries,
    setAllCountries,
    country,
    setCountry,
    state,
    setState,
    city,
    setCity,
  } = useGeolocation();
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const captcha = useRef();
  const [valid, setValid] = useState(false);

  useEffect(() => {
    setAllCountries(
      Country.getAllCountries().map((country) => ({
        label: `${country.flag} ${country.name}`,
        value: country.isoCode,
        ...country,
      }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setStates(
      State.getStatesOfCountry(country.isoCode).map((state) => ({
        label: state.name,
        value: state.name,
        ...state,
      }))
    );
  }, [country]);

  useEffect(() => {
    setCities(
      City.getCitiesOfState(country.isoCode, state.isoCode).map((city) => ({
        label: city.name,
        value: city.name,
        ...city,
      }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country, state]);

  useEffect(() => {
    if (Object.keys(client).length !== 0) {
      setValue("name", client.name);
      setValue("lastname", client.lastname);
      setValue("email", client.email);
      setValue("phone", client.phone);
      setValue("phone", client.phone);
      setValue("country", client.country);
      setValue("state", client.state);
      setValue("city", client.city || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCountryChange = (data) => {
    if (data) {
      setCountry(data);
      setValue("country", data.name);
      setState("");
      setValue("state", null);
      setCity("");
      setValue("city", null);
    }
  };

  const handleStateChange = (data) => {
    if (data) {
      setState(data);
      setValue("state", data.name);
      setCity("");
      setValue("city", null);
    }
  };

  const handleCityChange = (data) => {
    if (data) {
      setCity(data);
      setValue("city", data.name);
    }
  };

  const onCaptchaChange = () => {
    if (captcha.current.getValue()) {
      setValid(true);
    }
  };

  const onSubmit = (data) => {
    submitClient(data);
    reset({});
  };

  return (
    <div className="client-form-main-container">
      <Box className="client-form-grid-container">
        <Grid container className="client-form-grid">
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            className="client-form-grid-item"
          >
            <CustomTextInput
              name="name"
              control={control}
              label="Nombre *"
              type="text"
              rules={{
                required: {
                  value: true,
                  message: "Campo obligatorio",
                },
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            className="client-form-grid-item"
          >
            <CustomTextInput
              name="lastname"
              control={control}
              label="Apellido *"
              type="text"
              rules={{
                required: {
                  value: true,
                  message: "Campo obligatorio",
                },
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            className="client-form-grid-item"
          >
            <CustomTextInput
              name="email"
              control={control}
              label="E-mail *"
              type="email"
              rules={{
                required: {
                  value: true,
                  message: "Campo obligatorio",
                },
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@”]+(\.[^<>()[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/,
                  message: "El correo ingresado no es válido",
                },
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            className="client-form-grid-item"
          >
            <CustomTextInput
              name="phone"
              control={control}
              label={"Teléfono / Whatsapp *"}
              type={"number"}
              rules={{
                required: {
                  value: true,
                  message: "Campo obligatorio",
                },
                pattern: {
                  value: /^([0-9]{10,14})$/,
                  message: "El número ingresado no es válido",
                },
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            className="client-form-grid-item-select"
          >
            <CustomSelect
              name="country"
              control={control}
              options={allCountries}
              label={"País"}
              value={(Object.keys(client).length !== 0 && country) || ""}
              setOption={handleCountryChange}
              clear={clearErrors}
              rules={{
                required: {
                  value: true,
                  message: "Campo obligatorio",
                },
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            className="client-form-grid-item-select"
          >
            <CustomSelect
              name="state"
              control={control}
              options={states && states}
              value={(Object.keys(client).length !== 0 && state) || ""}
              label={"Provinicia / estado"}
              setOption={handleStateChange}
              clear={clearErrors}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            className="client-form-grid-item-select"
          >
            <CustomSelect
              name="city"
              control={control}
              value={(Object.keys(client).length !== 0 && city) || ""}
              options={cities && cities}
              label={"Ciudad"}
              setOption={handleCityChange}
              clear={clearErrors}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className="client-form-grid-item"
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <ReCAPTCHA
              ref={captcha}
              sitekey={process.env.REACT_APP_SITE_KEY}
              onChange={onCaptchaChange}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className="client-form-btn-container"
          >
            <Stack
              direction={{
                xs: "column-reverse",
                sm: "column-reverse",
                md: "row",
              }}
              spacing={{
                xs: 3,
                sm: 3,
                md: 6,
                lg: 6,
              }}
            >
              <Button onClick={goBack}>Anterior</Button>
              <Button onClick={handleSubmit(onSubmit)} disabled={!valid}>
                Siguiente
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default ClientForm;
