import { useContext } from 'react'
import FeedbackContext from '../../context/feedbackContext'

const useFeedback = () => {

    const { feedback, setFeedback } = useContext(FeedbackContext)

    const handleFeedbackClose = (evt, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setFeedback({ open: false, error: false, message: '' })
    }

    return {
        feedback,
        setFeedback,
        handleFeedbackClose
    }
}

export default useFeedback