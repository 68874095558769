import React from "react";
import "./styles.scss";
import { GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";

const CustomToolbar = () => {
  return (
    <div className="custom-toolbar">
      <GridToolbarContainer className="custom-toolbar-container">
        <GridToolbarQuickFilter className="custom-toolbar-search-bar" />
      </GridToolbarContainer>
    </div>
  );
};

export default CustomToolbar;
