import React, { useEffect } from "react";
import "./styles.scss";
import VerifyEmailAddress from "../../components/ResetPassForm";
import { getResetPasswordPresenter } from "../../infrastructure/presentation/presenterProvider";
import useUser from "../../../../hooks/useUser";
import Swal from "sweetalert2/src/sweetalert2.js";
import logoAlert from "../../../../assets/images/test.png";
import { useNavigate } from "react-router-dom";
import useFeedback from "../../../../hooks/useFeedback";

const ResetPassword = () => {
  const { isLogged } = useUser();
  const navigate = useNavigate();
  const { setFeedback } = useFeedback()

  const viewHandlers = {
    isAdmin(data) {
      console.log("<<<<<<isAdmin>>>>>>>", data.data.message);
      Swal.fire({
        title: `Bienvenido`,
        confirmButtonText: "Ingresar",
        customClass: {
          title: "admin-pass-title",
          confirmButton: "admin-pass-button",
        },
      });
    },
    isNotAdmin(err) {
      console.log("<<<<<<isAdmin>>>>>>>", err);
      Swal.fire({
        title: `Contraseña incorrecta`,
        confirmButtonText: "Aceptar",
        customClass: {
          title: "admin-pass-title",
          confirmButton: "admin-pass-button",
        },
      });
      setTimeout(() => {
        Swal.close();
        navigate("/");
      }, 2000);
    },
    verifyEmailSuccess(data) {
      console.log("<<<<<<Verify Email>>>>>>>", data.data.message);
      setFeedback({
        open: true,
        error: false,
        message: 'El correo ha sido enviado'
      })
    },
    verifyEmailError(err) {
      console.log("<<<<<<Verify Email Error>>>>>>>", err.response.data.message);
      setFeedback({
        open: true,
        error: true,
        message: 'No hemos podido enviar el correo'
      })
    },
  };

  const presenter = getResetPasswordPresenter(viewHandlers);

  useEffect(() => {
    presenter.present();
    if (!isLogged) {
      Swal.fire({
        inputLabel: "Ingrese su contraseña",
        input: "password",
        confirmButtonText: "Confirmar",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        inputAttributes: {
          autocapitalize: "off",
          autocorrect: "off",
        },
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        customClass: {
          inputLabel: "admin-pass-title",
          image: "admin-pass-image",
          htmlContainer: "admin-pass-text",
          input: "admin-pass-input",
          confirmButton: "admin-pass-button",
          cancelButton: "admin-pass-button",
        },
        imageUrl: logoAlert,
        imageAlt: "logo-mn",
        preConfirm: (value) => {
          presenter.isAdmin(value);
        },
      }).then((result) => {
        if (result.dismiss === Swal.DismissReason.cancel) {
          navigate(-1);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const verifyEmail = (data) => {
    presenter.verifyEmail(data);
  };

  return (
    <div className="reset-screen-main-container">
      <div className="reset-screen-title-container">
        <h2 className="reset-screen-title">Restaurar contraseña</h2>
      </div>
      <div className="reset-screen-form-container">
        <VerifyEmailAddress verify={verifyEmail} />
      </div>
    </div>
  );
};

export default ResetPassword;
