export const PackageDetailPresenter = (viewHandlers, getPackageGateway) => {
  return {
    present() {
      console.log("Pacakge detail was rendered!");
    },
    getPackage(id) {
      const getPackagePromise = getPackageGateway.getPackageById(id);

      getPackagePromise
        .then((data) => {
          viewHandlers.getPackageSuccess(data);
        })
        .catch((err) => {
          viewHandlers.getPackageError(err);
        });
    },
  };
};
