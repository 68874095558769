/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./styles.scss";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Divider, Stack, Typography } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EventIcon from "@mui/icons-material/Event";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useBagagge } from "../../../../hooks/useBagagge";
import AmplifiedImage from "../../components/AmplifiedImage";
import { getActivitiesDetailPresenter } from "../../infrastructure/presentation/presenterProvider";
import logo from '../../../../assets/images/1.jpeg'

const ActivitiesDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { checkActivityInBagagge, addToBagagge, removeFromBagagge } = useBagagge();
  const [activity, setActivity] = useState({});
  const [images, setImages] = useState([]);
  const [activeImage, setActiveImage] = useState(0)
  const [showZoom, setShowZoom] = useState(false)


  const viewHandlers = {
    getActivitySuccess(data) {
      console.log("DATA: ", data.statusText);
      setActivity(data.data.data);
    },
    getActivityError(error) {
      console.log("Error: ", error);
    },
  };

  const presenter = getActivitiesDetailPresenter(viewHandlers);

  useEffect(() => {
    presenter.present();
    presenter.getActivity(id);
  }, []);

  useEffect(() => {
    if (activity) {
      setImages(activity.images);
    }
  }, [activity]);

  const addActivity = (activity) => {
    addToBagagge(activity);
  };

  const removeActivity = (activity) => {
    removeFromBagagge(activity);
  };

  const isInBagagge = checkActivityInBagagge(activity);

  const handleZoom = (index) => {
    setActiveImage(index)
    setShowZoom((prevState) => !prevState)
  };

  return (
    <div className="activities-detail-screen">
      <div className="activities-detail-grid">
        <section className="activities-detail-image-section">
          {(images && images.length > 0) ? (
            <img
              src={`${images[0]?.url}`}
              alt="imagen de la actividad"
              className="activity-detail-image"
            />
          ) : (
            <img
              src={logo}
              alt="imagen de la actividad"
              className="activity-detail-image"
              style={{ borderRadius: '16px' }}
            />
          )}
        </section>
        <section className="activities-detail-info-container">
          <div className="activity-detail-title-container">
            <Typography variant="h5" gutterBottom className="activity-detail-title">
              {activity?.name}
            </Typography>
          </div>
          <Divider variant="middle" color={"#736384"} />
          <div className="activity-detail-data-container">
            <Stack
              direction={"column"}
              justifyContent={"space-evenly"}
              alignItems={"center"}
              spacing={2}
              className="activities-detail-stack"
            >
              <section className="activity-detail-data-card">
                <div className="icon-title-container">
                  <LocationOnIcon className="data-card-icon" />
                  <span className="data-card-title">Ubicación: </span>
                </div>
                <div className="data-card-content">
                  <Typography variant="body1">{activity?.location}</Typography>
                </div>
              </section>
              <section className="activity-detail-data-card">
                <div className="icon-title-container">
                  <AccessTimeIcon className="data-card-icon" />
                  <span className="data-card-title">Duración: </span>
                </div>
                <div className="data-card-content">
                  <Typography variant="body1">{`${activity?.duration} horas`}</Typography>
                </div>
              </section>
              <section className="activity-detail-data-card">
                <div className="icon-title-container">
                  <EventIcon className="data-card-icon" />
                  <span className="data-card-title">Disponibilidad: </span>
                </div>
                <div className="data-card-content">
                  {activity?.availability ? (
                    <div className="available">
                      <span>Disponible</span>
                      <CheckCircleOutlineIcon sx={{ ml: 0.5 }} />
                    </div>
                  ) : (
                    <div className="not-available">
                      <CancelIcon />
                      <span>No disponible</span>
                    </div>
                  )}
                </div>
              </section>
            </Stack>
          </div>
          <Divider variant="middle" color={"#736384"} />
          <div className="activity-detail-buttons-container">
            <Button
              className="detail-btn"
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate(-1)}
            >
              Volver
            </Button>
            <Button
              className="detail-btn"
              endIcon={!isInBagagge ? <AddCircleOutlineIcon /> : <RemoveCircleOutlineIcon />}
              onClick={
                !isInBagagge ? () => addActivity(activity) : () => removeActivity(activity)
              }
            >
              {!isInBagagge ? "Agregar" : "Quitar"}
            </Button>
          </div>
        </section>
        <section className="activities-detail-desc-container">
          <div className="activity-description-titile-container">
            <Typography variant="h5" gutterBottom className="activity-description-title">
              Descripción
            </Typography>
          </div>
          <Typography variant="subtitle1" className="activity-description">
            {activity?.description}
          </Typography>
        </section>
        {
          (images && images.length > 0) && (
            <section className="activity-images-gallery">
              <Typography variant="h4" gutterBottom className="image-gallery-title">
                Galería de imágenes
              </Typography>
              <div className="image-gallery">
                {images?.slice(0, 5).map((image, index) => (
                  <img
                    key={index}
                    onClick={() => handleZoom(index)}
                    src={image.url}
                    alt={image.name}
                    className="image-gallery-item"
                  />
                ))}
              </div>
            </section>
          )
        }
        {showZoom && (
          <AmplifiedImage
            images={images}
            activeImage={activeImage}
            onClose={handleZoom}
            open={showZoom}
          />
        )}
      </div>
    </div>
  );
};

export default ActivitiesDetail;
