export const MessagesCreatePresenter = (
  getCreateMessageGateway,
  viewHandlers
) => {
  return {
    present() {
      console.log("Contact was rendered!");
    },
    createMessage(data) {
      const createMessagePromise = getCreateMessageGateway.create(data);

      createMessagePromise
        .then((data) => {
          viewHandlers.createMessageSuccess(data);
        })
        .catch((err) => {
          viewHandlers.createMessageError(err);
        });
    },
  };
};
