import axios from "axios";

export const HttpCreateActivityGateway = () => {
  return {
    createActivity: async (data) => {
      const { name, location, duration, availability, description } = data;
      const body = {
        name,
        location,
        duration: Number(duration),
        availability,
        description,
      };
      return axios.post(
        `${process.env.REACT_APP_BASE_URL}/activities/create`,
        body
      );
    },
  };
};
