export const LoginPresenter = (
  getIsAdminGateway,
  getLoginGateway,
  viewHandlers
) => {
  return {
    present() {
      console.log("Login screen was rendered!");
    },
    login(data) {
      const loginPromise = getLoginGateway.login(data);

      loginPromise
        .then((data) => {
          viewHandlers.loginSuccess(data);
        })
        .catch((err) => {
          viewHandlers.loginError(err);
        });
    },
    isAdmin(data) {
      const isAdminPromise = getIsAdminGateway.verify(data);

      isAdminPromise
        .then((data) => {
          viewHandlers.isAdmin(data);
        })
        .catch((err) => {
          viewHandlers.isNotAdmin(err);
        });
    },
  };
};
