import { HttpGetAllActivitiesGateway } from "../gateways/HttpGetAllActivitiesGateway";
import { HttpCreateActivityGateway } from "../gateways/HttpCreateActivityGateway";
import { HttpDeleteActivityGateway } from "../gateways/HttpDeleteActivityGateway";
import { HttpUpdateActivityGateway } from "../gateways/HttpUpdateActivityGateway";
import { HttpGetActivityImagesGateway } from "../gateways/HttpGetActivityImagesGateway";
import { HttpDeleteImageGateway } from "../gateways/HttpDeleteImageGateway";
import { HttpGetActivityGateway } from "../gateways/HttpGetActivityGateway";

import { ActivitiesHomePresenter } from "./ActivitiesHomePresenter";
import { ActivitiesCreatePresenter } from "./ActivitiesCreatePresenter";
import { ActivitiesUpdatePresenter } from "./ActivitiesUpdatePresenter";
import { ActivitiesUploadImagesPresenter } from "./ActivitiesUploadImagesPresenter";
import { ActivitiesDetailPresenter } from "./ActivitiesDetailPresenter";

export const getActivitiesHomePresenter = (viewHandlers) => {
  const getAllActivitiesGateway = HttpGetAllActivitiesGateway();
  const deleteActivityGateway = HttpDeleteActivityGateway();
  return ActivitiesHomePresenter(getAllActivitiesGateway, deleteActivityGateway, viewHandlers);
};

export const getActivitiesCreatePresenter = (viewHandlers) => {
  const getCreateActivityGateway = HttpCreateActivityGateway();
  return ActivitiesCreatePresenter(getCreateActivityGateway, viewHandlers);
};

export const getActivitiesUpdatePresenter = (viewHandlers) => {
  const getUpdateActivityGateway = HttpUpdateActivityGateway();
  return ActivitiesUpdatePresenter(viewHandlers, getUpdateActivityGateway);
};

export const getActivitiesUploadImagesPresenter = (viewHandlers) => {
  const getActivitiesImagesGateway = HttpGetActivityImagesGateway();
  const getDeleteImagesGateway = HttpDeleteImageGateway();
  return ActivitiesUploadImagesPresenter(
    getActivitiesImagesGateway,
    getDeleteImagesGateway,
    viewHandlers
  );
};

export const getActivitiesDetailPresenter = (viewHandlers) => {
  const getImagesGateway = HttpGetActivityImagesGateway();
  const getActivityGateway = HttpGetActivityGateway();
  return ActivitiesDetailPresenter(getImagesGateway, getActivityGateway, viewHandlers);
};
