import { useContext } from "react";
import LocationContext from "../../context/locationContext";

export const useGeolocation = () => {
  const geolocation = useContext(LocationContext);

  if (geolocation === undefined) {
    throw new Error("useGeolocation must be used withing a BagaggeProvider");
  }

  return geolocation;
};
