export const ActivitiesHomePresenter = (
  getAllActivitiesGateway,
  deleteActivityGateway,
  viewHandlers
) => {
  return {
    present() {
      console.log("Activities home was rendered!");
    },

    getAllActivities() {
      const getActivitiesPromise = getAllActivitiesGateway.getActivities();

      getActivitiesPromise
        .then((data) => {
          viewHandlers.getActivitiesSuccess(data);
        })
        .catch((err) => {
          viewHandlers.getActivitiesError(err);
        });
    },

    deleteActivity(id) {
      const deleteActivityPromise = deleteActivityGateway.deleteActivity(id);

      deleteActivityPromise
        .then((data) => {
          viewHandlers.deleteActivitySuccess(data);
        })
        .catch((err) => {
          viewHandlers.deleteActivityError(err);
        });
    },
  };
};
