/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import "./styles.scss";
import { Box, Divider, Typography } from "@mui/material";
import ActivitiesForm from "../../components/ActivitiesForm";
import { getActivitiesCreatePresenter } from "../../infrastructure/presentation/presenterProvider";
import useFeedback from "../../../../hooks/useFeedback";

const ActivitiesCreate = () => {
  const { setFeedback } = useFeedback()

  const viewHandlers = {
    createActivitySuccess(data) {
      console.log("<<<<<<DATA>>>>>>>", data.statusText);
      setFeedback({
        open: true,
        error: false,
        message: 'Actividad creada con éxito'
      })
    },
    createActivityError(err) {
      console.log("<<<<<<ERROR>>>>>>>", err);
      setFeedback({
        open: true,
        error: true,
        message: 'No se pudo crear la actividad'
      })
    },
  };

  const presenter = getActivitiesCreatePresenter(viewHandlers);

  const createNewActivity = (data) => {
    presenter.createActivity(data);
  };

  useEffect(() => {
    presenter.present();
  }, []);

  return (
    <div className="activities-create-screen">
      <Box className="activities-create-main-container">
        <div className="activities-create-title-container">
          <Typography
            variant="h3"
            gutterBottom
            className="activities-create-title"
          >
            Nueva Actividad
          </Typography>
          <Divider variant="middle" className="separator" />
        </div>
        <div className="activities-create-form-container">
          <ActivitiesForm create={createNewActivity} />
        </div>
      </Box>
    </div>
  );
};

export default ActivitiesCreate;
