export const ActivitiesUploadImagesPresenter = (
  getActivityImagesGateway,
  getDeleteImagesGateway,
  viewHandlers
) => {
  return {
    present() {
      console.log("Upload was rendered!");
    },

    getActivityImages(id) {
      const getImagesPromise = getActivityImagesGateway.getImages(id);

      getImagesPromise
        .then((data) => {
          viewHandlers.getImagesSuccess(data);
        })
        .catch((err) => {
          viewHandlers.getImagesError(err);
        });
    },

    deleteActivityImage(id) {
      const deleteImagePromise = getDeleteImagesGateway.deleteImage(id);

      deleteImagePromise
        .then((data) => {
          viewHandlers.deleteImageSuccess(data);
        })
        .catch((err) => {
          viewHandlers.deleteImageError(err);
        });
    },
  };
};
