export const MessagesHomePresenter = (
  getAllMessagesGateway,
  getDeleteMessageGateway,
  viewHandlers
) => {
  return {
    present() {
      console.log("Messages home was rendered!");
    },
    getMessages() {
      const getMessagesPromise = getAllMessagesGateway.getAllMessages();

      getMessagesPromise
        .then((data) => {
          viewHandlers.getAllMessagesSuccess(data);
        })
        .catch((err) => {
          viewHandlers.getAllMessagesError(err);
        });
    },
    deleteMessage(id) {
      const deleteMessagePromise = getDeleteMessageGateway.deleteMessage(id);

      deleteMessagePromise
        .then((data) => {
          viewHandlers.deleteMessageSuccess(data);
        })
        .catch((err) => {
          viewHandlers.deleteMessageError(err);
        });
    },
  };
};
