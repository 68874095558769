import React, { createContext, useState } from "react";

const StepperContext = createContext();

const StepperContextProvider = ({ children }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const reset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const data = {
    activeStep,
    completed,
    handleBack,
    handleNext,
    reset,
  };

  return (
    <StepperContext.Provider value={data}>{children}</StepperContext.Provider>
  );
};

export { StepperContextProvider };

export default StepperContext;
