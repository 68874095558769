import axios from "axios";

export const HttpCreatePackageGateway = () => {
  return {
    create: async (data) => {
      const { bagagge, client, stay } = data;
      const body = {
        activities: bagagge,
        client,
        package: stay,
      };
      return axios.post(
        `${process.env.REACT_APP_BASE_URL}/packages/create`,
        body
      );
    },
  };
};
