import React from "react";
import "./App.scss";
import theme from "./ThemeConfig";
import Navbar from "./modules/Landing/components/Navbar";
import { Route, Routes } from "react-router-dom";
import Login from "./modules/Onboarding/views/Login";
import { ThemeProvider } from "@emotion/react";
import Register from "./modules/Onboarding/views/Register";
import ResetPassword from "./modules/Onboarding/views/ResetPassword";
import LandingPage from "./modules/Landing/views";
import { UserContextProvider } from "./context/userContext";
import ChangePassword from "./modules/Onboarding/views/ChangePassword";
import * as webAppRoutes from "./constants/webAppRoutes";
import MessagesCreate from "./modules/Messages/views/Create";
import PackagesHome from "./modules/Packages/views/Home";
import ActivitiesDetail from "./modules/Activities/views/Detail";
import { BagaggeContextProvider } from "./context/bagaggeContext";
import { LocationContextProvider } from "./context/locationContext";
import { StepperContextProvider } from "./context/stepperContext";
import AboutPage from "./modules/About/views/AboutPage";
import { FeedbackContextProvider } from "./context/feedbackContext";
import ScrollComponent from "./components/UI/ScrollComponent";

function App() {
  return (
    <div className="app">
      <ScrollComponent />
      <ThemeProvider theme={theme}>
        <FeedbackContextProvider>
          <UserContextProvider>
            <StepperContextProvider>
              <BagaggeContextProvider>
                <LocationContextProvider>
                  <Navbar>
                    <Routes>
                      <Route path="/*" element={<>404</>} />
                      <Route path="/" index element={<LandingPage />} />
                      <Route path="/about" element={<AboutPage />} />
                      <Route path="/packages" element={<PackagesHome />} />
                      <Route path="/activities/detail/:id" element={<ActivitiesDetail />} />
                      <Route path="/contact" element={<MessagesCreate />} />
                      <Route path="/services" element={<>Servicios</>} />
                      <Route path="/login" element={<Login />} />
                      <Route path="/signin" element={<Register />} />
                      <Route path="/resetpassword" element={<ResetPassword />} />
                      <Route
                        path="/changepassword/verify-token/:token"
                        element={<ChangePassword />}
                      />
                      <Route
                        path={webAppRoutes.ACTIVITIES_HOME.path}
                        element={webAppRoutes.ACTIVITIES_HOME.element}
                      />
                      <Route
                        path={webAppRoutes.ACTIVITIES_CREATE.path}
                        element={webAppRoutes.ACTIVITIES_CREATE.element}
                      />
                      <Route
                        path={webAppRoutes.ACTIVITIES_UPDATE.path}
                        element={webAppRoutes.ACTIVITIES_UPDATE.element}
                      />
                      <Route
                        path={webAppRoutes.ACTIVITIES_ADD_IMAGES.path}
                        element={webAppRoutes.ACTIVITIES_ADD_IMAGES.element}
                      />
                      {/* Messages */}
                      <Route
                        path={webAppRoutes.MESSAGES_HOME.path}
                        element={webAppRoutes.MESSAGES_HOME.element}
                      />
                      <Route
                        path={webAppRoutes.MESSAGES_DETAIL.path}
                        element={webAppRoutes.MESSAGES_DETAIL.element}
                      />
                      {/* Packages admin */}
                      <Route
                        path={webAppRoutes.PACKAGES_ADMIN.path}
                        element={webAppRoutes.PACKAGES_ADMIN.element}
                      />
                      <Route
                        path={webAppRoutes.PACKAGES_DETAIL.path}
                        element={webAppRoutes.PACKAGES_DETAIL.element}
                      />
                    </Routes>
                  </Navbar>
                </LocationContextProvider>
              </BagaggeContextProvider>
            </StepperContextProvider>
          </UserContextProvider>
        </FeedbackContextProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
