import React from "react";
import "./styles.scss";
import { GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";

const TableToolbar = () => {
  return (
    <div className="messages-table-toolbar">
      <GridToolbarContainer className="messages-table-toolbar-container">
        <GridToolbarQuickFilter className="messages-table-search-bar" />
      </GridToolbarContainer>
    </div>
  );
};

export default TableToolbar;
