import React from "react";
import "./styles.scss";
import { Divider, Stack, Typography } from "@mui/material";
import PersonalCard from "../../components/PersonalCard";
import img1 from "../../../../assets/images/marcos.jpg";
import img2 from "../../../../assets/images/alejandro.png";

const AboutPage = () => {
  return (
    <div className="about-screen">
      <section className="about-screen-header">
        <Stack
          className="about-screen-header-stack"
          direction={{
            xs: "column-reverse",
            sm: "column-reverse",
            md: "row",
            lg: "row",
          }}
          spacing={{ xs: 0, sm: 0, md: 3, lg: 3 }}
          divider={<Divider orientation="vertical" flexItem />}
        >
          <div className="header-stack-inner-container">
            <Stack direction={"column"} spacing={4} className="about-text-stack">
              <div>
                <Typography variant="h6" gutterBottom className="about-text-title">
                  Nuestra misión:
                </Typography>
                <Typography variant="body1">
                  Ofrecer servicios de viajes y turismo personalizados, de calidad y adaptados
                  a las necesidades y expectativas de nuestros clientes, brindando y creando
                  experiencias únicas e inolvidables en Mendoza, una provincia rica en vinos,
                  gastronomía, cultura y naturaleza.
                </Typography>
              </div>
              <div>
                <Typography variant="h6" gutterBottom className="about-text-title">
                  Nuestra visión:
                </Typography>
                <Typography variant="body1">
                  Ser productores de turismo líderes y reconocidos en el mercado local e
                  internacional, distinguidos por el profesionalismo, la innovación,
                  creatividad y compromiso con la satisfacción de sus clientes y el desarrollo
                  sustentable del destino.
                </Typography>
              </div>
            </Stack>
          </div>

          <div className="header-stack-inner-container">
            <div className="about-stack-img-container">
              <div className="about-logo"></div>
            </div>
          </div>
        </Stack>
      </section>
      <section className="about-screen-body"></section>
      <section className="about-screen-footer">
        <div className="about-footer-title-container">
          <h4 className="about-footer-title">Fundadores</h4>
        </div>
        <Stack
          className="about-cards-container"
          direction={{ xs: "column", sm: "column", md: "row", lg: "row" }}
          spacing={{ xs: 3, sm: 3, md: 3, lg: 3 }}
        >
          <PersonalCard
            name={"Marcos Martinez"}
            subtitle={
              "Soy un Licenciado en Gestion de Empresas Turísticas y Hoteleras con más de 15 años de experiencia en la industria. He demostrado una fuerte vocación de servicio y un compromiso con la excelencia. Mi trayectoria se ha caracterizado por una gestión innovadora y estratégica en diversas áreas del turismo y la hotelería"
            }
            img={img1}
          />
          <PersonalCard
            name={"Alejandro Montiel"}
            subtitle={
              "Soy un apasionado del turismo, además soy Licenciado en Gestión de empresas Turísticas y Hoteleras. Hace más de 15 años que me desempeño en el área, que amo y pienso que es una de las industrias más importantes en el mundo. Considero que viajar es vivir y llenar la vida de historias."
            }
            img={img2}
          />
        </Stack>
      </section>
    </div>
  );
};

export default AboutPage;
