import axios from "axios";

export const HttpDeleteImageGateway = () => {
  return {
    deleteImage: async (id) => {
      return axios.delete(
        `${process.env.REACT_APP_BASE_URL}/activities/upload/delete/id/${id}`
      );
    },
  };
};
