import React, { useState } from 'react';
import { createContext } from 'react';

const FeedbackContext = createContext();

const FeedbackContextProvider = ({ children }) => {

    const [feedback, setFeedback] = useState({
        open: false,
        error: false,
        message: ''
    })

    const data = { feedback, setFeedback }

    return <FeedbackContext.Provider value={data}>{children}</FeedbackContext.Provider>
}

export { FeedbackContextProvider }

export default FeedbackContext