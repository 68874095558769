import { useContext } from "react";
import StepperContext from "../../context/stepperContext";

export const useStepper = () => {
  const stepper = useContext(StepperContext);

  if (stepper === undefined) {
    throw new Error("useStepper must be used withing a BagaggeProvider");
  }

  return stepper;
};
