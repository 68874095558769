import React from "react";
import "./styles.scss";
import { Stack, Typography } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import logo from "../../../assets/images/2-png.png";
import register from '../../../assets/images/registro.png'
import FacebookIcon from "@mui/icons-material/Facebook";
// import TwitterIcon from "@mui/icons-material/Twitter";

const Footer = () => {
  return (
    <footer className="landing-footer">
      <Stack
        className="landing-footer-main-stack"
        direction={{ xs: "column", sm: "column", md: "row", lg: "row" }}
        spacing={{ xs: 0, sm: 0, md: 1, lg: 1 }}
        justifyContent={"space-between"}
      >
        <div className="landing-footer-social-media">
          <Typography
            variant="overline"
            display="block"
            gutterBottom
            className="social-media-title"
          >
            Nuestras redes
          </Typography>
          <Stack direction="row" spacing={3}>
            <a
              href="https://www.facebook.com/people/Monmar-Travel/61557974402270/?mibextid=qi2Omg&rdid=zmK8jtJpXg1DsTVJ"
              target="_blank"
              rel="noreferrer"
            >
              <FacebookIcon fontSize="large" className="social-media-icons" />
            </a>
            <a
              href="https://www.instagram.com/monmartravel/"
              target="_blank"
              rel="noreferrer"
            >
              <InstagramIcon fontSize="large" className="social-media-icons" />
            </a>
            {/* <a
              href="https://stackoverflow.com/"
              target="_blank"
              rel="noreferrer"
            >
              <TwitterIcon fontSize="large" className="social-media-icons" />
            </a> */}
            <a
              href='https://api.whatsapp.com/send?phone=5492617035320'
              target="_blank"
              rel="noreferrer"
            >
              <WhatsAppIcon fontSize="large" className="social-media-icons" />
            </a>
          </Stack>
        </div>

        <div className="landing-footer-logo-container">
          <div className="footer-logo-button">
            <img src={logo} alt="monmar-logo" className="footer-logo" />
          </div>
        </div>

        <div className='register-container'>
          <img src={register} alt="logo del registro nacional de agencias de viajes" className="register-logo" />
          <Typography variant='body1' className="regiter-title" sx={{ textAlign: 'center' }}>
            Legajo N°19065
          </Typography>
        </div>

      </Stack>
    </footer>
  );
};

export default Footer;
