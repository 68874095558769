import axios from "axios";

export const HttpIsAdminGateway = () => {
  return {
    verify: async (data) => {
      return axios.post(`${process.env.REACT_APP_BASE_URL}/login/admin`, {
        pass: data,
      });
    },
  };
};
