import axios from "axios";

export const HttpUpdateActivityGateway = () => {
  return {
    update: async (data) => {
      const { id, name, duration, location, description, availability } = data;
      const body = {
        id: Number(id),
        name,
        duration: Number(duration),
        location,
        description,
        availability,
      };

      return axios.put(
        `${process.env.REACT_APP_BASE_URL}/activities/update`,
        body
      );
    },
  };
};
