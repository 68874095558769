import React, { useEffect, useState } from "react";
import "./styles.scss";
import { useNavigate, useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import {
  DialogContentText,
  Button,
  DialogContent,
  Divider,
  Grid,
  Stack,
  Typography,
  DialogTitle,
  Select,
  Box,
  FormControl,
  MenuItem,
} from "@mui/material";
import MessageModal from "../../components/MessagesModal";
import { getMessagesUpdatePresenter } from "../../infrastructure/presentation/presenterProvider";

const MessagesDetail = () => {
  const { id } = useParams();
  const [message, setMessage] = useState({});
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [newStatus, setNewStatus] = useState("");
  const [refresh, setRefresh] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChange = (evt) => {
    setNewStatus(evt.target.value);
  };

  const viewHandlers = {
    updateMessageSuccess(data) {
      console.log("<<<<<<UPDATE>>>>>>> ", data.statusText);
    },
    updateMessageError(err) {
      console.log("<<<<<<UPDATE ERROR>>>>>>> ", err);
    },
    getMessageSuccess(data) {
      console.log("<<<<<<MESSAGE>>>>>>> ", data.statusText);
      setMessage(data.data.data);
    },
    getMessageError(err) {
      console.log("<<<<<<MESSAGE ERROR>>>>>>> ", err);
    },
  };

  const presenter = getMessagesUpdatePresenter(viewHandlers);

  useEffect(() => {
    presenter.present();
    presenter.getMessage(id);
    return () => {
      setTimeout(() => {
        setRefresh(false);
      }, 2000);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const ExecuteUpdate = (id) => {
    const data = { id, newStatus };
    presenter.updateMessage(data);
    setRefresh(true);
  };

  return (
    <div className="message-detail-screen">
      <div className="message-detail-title-container">
        <Typography variant="h4" className="message-detail-title">
          Detalles
        </Typography>
        <Button
          variant="outlined"
          endIcon={<EditIcon />}
          className="update-status-icon"
          onClick={handleOpen}
        >
          Actualizar estado
        </Button>
      </div>
      <div className="message-detail-data-container">
        <Grid container spacing={2} className="message-detail-grid">
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            className="message-detail-grid-item"
          >
            <div className="message-detail-data">
              <Stack
                direction={"column"}
                divider={
                  <Divider orientation="horizontal" flexItem color="#704863" />
                }
                justifyContent={"space-around"}
              >
                <Typography
                  variant="overline"
                  display="block"
                  className="message-detail-data-title"
                >
                  Estado
                </Typography>
                <Typography
                  variatn="subtitle1"
                  display="block"
                  gutterBottom
                  className="message-detail-data-content"
                >
                  {message.status === "new"
                    ? "Nuevo"
                    : message.status === "seen"
                    ? "Visto"
                    : message.status === "answered"
                    ? "Contestado"
                    : message.status === "cancelled"
                    ? "Cancelado"
                    : message.status === "deleted" && "Eliminado"}
                </Typography>
              </Stack>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            className="message-detail-grid-item"
          >
            <div className="message-detail-data">
              <Stack
                direction={"column"}
                divider={
                  <Divider orientation="horizontal" flexItem color="#704863" />
                }
                justifyContent={"space-around"}
              >
                <Typography
                  variant="overline"
                  display="block"
                  className="message-detail-data-title"
                >
                  Nombre completo
                </Typography>
                <Typography
                  variatn="subtitle1"
                  display="block"
                  gutterBottom
                  className="message-detail-data-content"
                >
                  {`${message.name} ${message.lastname}`}
                </Typography>
              </Stack>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            className="message-detail-grid-item"
          >
            <div className="message-detail-data">
              <Stack
                direction={"column"}
                divider={
                  <Divider orientation="horizontal" flexItem color="#704863" />
                }
                justifyContent={"space-around"}
              >
                <Typography
                  variant="overline"
                  display="block"
                  className="message-detail-data-title"
                >
                  Fecha
                </Typography>
                <Typography
                  variatn="subtitle1"
                  display="block"
                  gutterBottom
                  className="message-detail-data-content"
                >
                  {new Date(message.created_at).toLocaleDateString()}
                </Typography>
              </Stack>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            className="message-detail-grid-item"
          >
            <div className="message-detail-data">
              <Stack
                direction={"column"}
                divider={
                  <Divider orientation="horizontal" flexItem color="#704863" />
                }
                justifyContent={"space-around"}
              >
                <Typography
                  variant="overline"
                  display="block"
                  className="message-detail-data-title"
                >
                  Email
                </Typography>
                <Typography
                  variatn="subtitle1"
                  display="block"
                  gutterBottom
                  className="message-detail-data-content"
                >
                  {message.email}
                </Typography>
              </Stack>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            className="message-detail-grid-item"
          >
            <div className="message-detail-data">
              <Stack
                direction={"column"}
                divider={
                  <Divider orientation="horizontal" flexItem color="#704863" />
                }
                justifyContent={"space-around"}
              >
                <Typography
                  variant="overline"
                  display="block"
                  className="message-detail-data-title"
                >
                  Teléfono
                </Typography>
                <Typography
                  variatn="subtitle1"
                  display="block"
                  gutterBottom
                  className="message-detail-data-content"
                >
                  {message.phone}
                </Typography>
              </Stack>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className="message-detail-grid-item"
          >
            <div className="message-detail-data">
              <div className="consultation-title-container">
                <Typography
                  variant="overline"
                  display="block"
                  className="message-detail-data-title"
                >
                  Consulta
                </Typography>
              </div>
              <Divider orientation="horizontal" flexItem color="#704863" />
              <div className="consultation">{message.consultation}</div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="message-detail-button-container">
        <Button
          variant="outlined"
          onClick={() => navigate(-1)}
          className="message-detail-back-button"
        >
          Volver
        </Button>
      </div>
      {open && (
        <MessageModal
          show={open}
          setShow={setOpen}
          obj={message}
          action={ExecuteUpdate}
        >
          <div className="delete-messages-modal-title-container">
            <DialogTitle
              id="alert-dialog-title"
              className="delete-messages-modal-title"
            >
              Actualizar estado
            </DialogTitle>
          </div>
          <div className="delete-messages-modal-content">
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Seleccione el nuevo estado del mensaje
              </DialogContentText>
              <div className="status-select-container">
                <Box>
                  <FormControl fullWidth>
                    <Select
                      labelId="status-selector"
                      id="select-status"
                      value={newStatus}
                      onChange={handleChange}
                    >
                      <MenuItem value={"new"}>Nuevo</MenuItem>
                      <MenuItem value={"seen"}>Visto</MenuItem>
                      <MenuItem value={"answered"}>Contestado</MenuItem>
                      <MenuItem value={"cancelled"}>Cancelado</MenuItem>
                      <MenuItem value={"deleted"}>Eliminado</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </div>
            </DialogContent>
          </div>
        </MessageModal>
      )}
    </div>
  );
};

export default MessagesDetail;
