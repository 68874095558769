import axios from "axios";

export const HttpUpdateMessageGateway = () => {
  return {
    update: async (data) => {
      const { id, newStatus } = data;
      const body = {
        id: Number(id),
        status: newStatus,
      };
      return axios.put(
        `${process.env.REACT_APP_BASE_URL}/contact/update`,
        body
      );
    },
  };
};
