import { useContext, useCallback } from "react";
import jwtDecode from "jwt-decode";
import UserContext from "../../context/userContext";

const useUser = () => {
  const { jwt, setJwt, status, setStatus, user, setUser, decoded, setDecoded } =
    useContext(UserContext);

  const login = useCallback(
    (res) => {
      setStatus({ loading: true, error: false });
      try {
        window.sessionStorage.setItem("jwt", res.token);
        window.sessionStorage.setItem("userData", JSON.stringify(res.data));
        setStatus({ loading: false, error: false });
        setJwt(res.token);
        setDecoded(jwtDecode(res.token));
        setUser(res.data);
      } catch (err) {
        window.sessionStorage.removeItem("jwt");
        window.sessionStorage.removeItem("userData");
        setStatus({ loading: false, error: true });
      }
    },
    [setJwt]
  );

  const logout = () => {
    window.sessionStorage.removeItem("jwt");
    window.sessionStorage.removeItem("userData");
    setStatus({ loading: false, error: false });
    setJwt(null);
    setUser(null);
    setDecoded({});
  };

  const verifySessionStatus = () => {
    const now = Number((new Date().getTime() / 1000).toFixed());
    const exp = decoded.exp;
    if (now >= exp) {
      logout();
      return false;
    } else {
      return true;
    }
  };

  return {
    isLogged: Boolean(jwt),
    isLogginLoading: status.loading,
    hasLoginError: status.error,
    user,
    login,
    logout,
    verifySessionStatus,
  };
};

export default useUser;
