import React, { useEffect, useState } from "react";
import "./styles.scss";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useNavigate, useParams } from "react-router-dom";
import { getPackageDetailPresenter } from "../../infrastructure/presentation/PresenterProvider";
import {
  Box,
  Button,
  Divider,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TabPanel from "../../../../components/UI/TabPanel";
import CheckoutList from "../../../../components/UI/CheckoutList";
import CheckoutStayGrid from "../../../../components/UI/CheckoutStayGrid";
import CheckoutContactGrid from "../../../../components/UI/CheckoutContactGrid";

const allyProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const PackagesDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [packageData, setPackageData] = useState({});
  const [packageBody, setPackageBody] = useState({});
  const [value, setValue] = useState(0);
  dayjs.extend(customParseFormat);

  const viewHandlers = {
    getPackageSuccess(data) {
      console.log("Package: ", data.statusText);
      setPackageData(data.data.data);
    },
    getPackageError(err) {
      console.log("Package error: ", err);
    },
  };

  const presenter = getPackageDetailPresenter(viewHandlers);

  useEffect(() => {
    presenter.present();
    presenter.getPackage(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (evt, newValue) => {
    setValue(newValue);
  };

  const handleMail = (email) => {
    const sendEmail = document.createElement("a");
    sendEmail.href = `https://mail.google.com/mail/?view=cm&fs=1&to=${email}`;
    sendEmail.target = "_blank";
    sendEmail.click();
  };

  const handlePhone = (phone) => {
    const sendMessage = document.createElement("a");
    sendMessage.href = `https://api.whatsapp.com/send?phone=${phone}`;
    sendMessage.target = "_blank";
    sendMessage.click();
  };

  const handleBack = () => {
    navigate(-1);
  };

  const correctDate = (date) => {
    const auxDate = date.split("/");
    return `${auxDate[1]}/${auxDate[0]}/${auxDate[2]}`;
  };

  useEffect(() => {
    if (Object.keys(packageData).length !== 0) {
      setPackageBody({
        from_date: correctDate(packageData.from_date),
        to_date: correctDate(packageData.to_date),
        people: packageData.people,
        consultation: packageData.consultation,
      });
    }
  }, [packageData]);

  return (
    <div className="packages-detail-screen">
      <div className="packages-detail-title-container">
        <Typography variant="h5" className="packages-detail-title" gutterBottom>
          Detalles del paquete
        </Typography>
      </div>
      <Divider variant="middle" className="packages-detail-separator" />
      <Box className="packages-detail-tabs-container">
        <Box className="packages-tabs-buttons">
          <Tabs
            className="packages-detail-button-nav"
            value={value}
            onChange={handleChange}
            aria-label="tabs"
            variant="fullWidth"
            textColor="secondary"
            indicatorColor="none"
            sx={{
              "& button": { backgroundColor: "#eee" },
              "& button.Mui-selected": {
                backgroundColor: "#E4DBEE",
              },
            }}
          >
            <Tab
              className="packages-detail-tab"
              label="Actividades"
              {...allyProps(0)}
              wrapped
            />
            <Tab
              className="packages-detail-tab"
              label="Estadía"
              {...allyProps(0)}
              wrapped
            />
            <Tab
              className="packages-detail-tab"
              label="Cliente"
              {...allyProps(0)}
              wrapped
            />
          </Tabs>
        </Box>
        <div className="packages-detail-tabpanel-container">
          <TabPanel
            value={value}
            index={0}
            className="packages-detail-tabpanel"
          >
            <CheckoutList data={packageData && packageData.activities} />
          </TabPanel>
          <TabPanel
            value={value}
            index={1}
            className="packages-detail-tabpanel"
          >
            <CheckoutStayGrid data={packageBody} />
          </TabPanel>
          <TabPanel
            value={value}
            index={2}
            className="packages-detail-tabpanel"
          >
            <CheckoutContactGrid data={packageData && packageData.client} />
            {packageData && packageData.client && (
              <>
                <div className="detail-packages-btn-container">
                  <Stack
                    direction={{
                      xs: "column-reverse",
                      sm: "column-reverse",
                      md: "row",
                    }}
                    spacing={{
                      xs: 3,
                      sm: 3,
                      md: 6,
                      lg: 6,
                    }}
                  >
                    <Button
                      startIcon={<EmailIcon />}
                      onClick={() => handleMail(packageData.client.email)}
                    >
                      Enviar correo
                    </Button>
                    <Button
                      startIcon={<WhatsAppIcon />}
                      onClick={() => handlePhone(packageData.client.phone)}
                    >
                      Enviar whatsapp
                    </Button>
                  </Stack>
                </div>
              </>
            )}
          </TabPanel>
        </div>
      </Box>
      <Button onClick={handleBack} className="detail-packages-back-btn">
        Volver
      </Button>
    </div>
  );
};

export default PackagesDetail;
