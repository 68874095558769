import React from "react";
import "./styles.scss";

const PersonalCard = ({ name, img, subtitle }) => {
  return (
    <div className="card-main-container">
      <div className="card-img"></div>
      <div className="card-avatar">
        <img src={img} alt="profile-pic" className="card-avatar-img" />
      </div>
      <div className="card-title">{name}</div>
      <div className="card-subtitle">{subtitle}</div>
    </div>
  );
};

export default PersonalCard;
