import React from "react";
import "./styles.scss";
import { IconButton, Stack, Tooltip } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { useNavigate } from "react-router-dom";

const ActionButtons = ({ activity, setOpen, open, setActivity }) => {
  const navigate = useNavigate();
  const { id } = activity;

  const toUpload = () => {
    navigate(`/activities/upload/${id}`, { state: { activity } });
  };

  const toDetail = () => {
    /* navigate(`/activities/detail/${id}`); */
    navigate(`/activities/detail/${id}`, { state: { activity } });
  };

  const toEdit = () => {
    navigate(`/activities/update/${id}`, { state: { activity } });
  };

  const toDelete = () => {
    setOpen(!open);
    setActivity(activity);
  };

  return (
    <Stack
      direction="row"
      spacing={{ xs: 1, sm: 1, md: 2, lg: 2 }}
      className="action-buttons-stack"
    >
      <Tooltip
        title="Agregar imágenes"
        placement="top"
        className="action-buttons-tooltips"
      >
        <IconButton className="action-buttons-icon" onClick={toUpload}>
          <AddAPhotoIcon fontSize="medium" />
        </IconButton>
      </Tooltip>
      <Tooltip
        title="Ver detalle"
        placement="top"
        className="action-buttons-tooltips"
      >
        <IconButton className="action-buttons-icon" onClick={toDetail}>
          <VisibilityIcon fontSize="medium" />
        </IconButton>
      </Tooltip>
      <Tooltip
        title="Editar"
        placement="top"
        className="action-buttons-tooltips"
      >
        <IconButton className="action-buttons-icon" onClick={toEdit}>
          <EditIcon fontSize="medium" />
        </IconButton>
      </Tooltip>
      <Tooltip
        title="Eliminar"
        placement="top"
        className="action-buttons-tooltips"
      >
        <IconButton className="action-buttons-icon" onClick={toDelete}>
          <DeleteIcon fontSize="medium" />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

export default ActionButtons;
